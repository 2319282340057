import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    Button,
    Col,
    // Image,
    Modal,
    Pagination,
    Row,
    Spin,
    Upload,
    UploadFile,
} from 'antd';
import {
    PlusCircleOutlined,
    UploadOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
} from '@ant-design/icons';
import {
    useAddDocumentMutation,
    useDeleteDocumentsMutation,
    useGetDocumentsQuery,
} from '../../../redux/features/workflows/services/workflows.api';
import classes from './workflow.components.module.css';
import { DocumentType } from './upsert.message.component';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Image, Gallery } from 'react-grid-gallery';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface UploadModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    title: string;
    messageType: number;
    doc: any;
    setDoc: (doc: any) => void;
    resetFields: (fields: string[]) => void;
    fieldNames: string[];
    setMessageType: any;
}

type Direction = 'ltr' | 'rtl';

const UploadModal = ({
    isModalOpen,
    setIsModalOpen,
    title,
    messageType,
    doc,
    setDoc,
    resetFields,
    fieldNames,
    setMessageType,
}: UploadModalProps) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [files, setFiles] = useState<UploadFile<any>[] | undefined>(
        undefined,
    );
    const [pageSize, setPageSize] = useState<number>(6);
    const [page, setPage] = useState<number>(1);
    const [skipGetDocument, setSkipGetDocument] = useState<boolean>(true);
    const [selectedDoc, setSelectedDoc] = useState<any>();
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [images, setImages] = useState<Image[]>([]);
    const { data, isLoading, isError, isUninitialized } = useGetDocumentsQuery(
        {
            type: messageType,
            page,
            limit: pageSize,
        },
        { skip: skipGetDocument },
    );
    useLayoutEffect(() => {
        if (isModalOpen) {
            setSkipGetDocument(false);
        } else {
            setSkipGetDocument(true);
        }
    }, [isModalOpen]);

    const [deleteDoc, { isLoading: isDeleting }] = useDeleteDocumentsMutation();
    const [addDoc, { isLoading: isAddingDoc }] = useAddDocumentMutation();

    const handleOk = () => {
        setIsModalOpen(false);
        setDoc(selectedDoc);
        setSelectedDoc(undefined);
    };

    const handleCancel = () => {
        setMessageType(undefined);
        setDoc(undefined);
        setIsModalOpen(false);
        setSelectedDoc(undefined);
        resetFields(fieldNames);
    };

    const handleSelect = (index: number) => {
        const selectedItem = images[index];
        const nextImages = images.map((image, i) => ({
            ...image,
            isSelected: i === index ? !image.isSelected : false,
        }));
        setImages(nextImages);
        setSelectedDoc({ id: selectedItem.key, url: selectedItem.src });
    };

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);

    useEffect(() => {
        setSelectedDoc(undefined);
    }, []);

    const handleDeleteDoc = async () => {
        await deleteDoc({
            id: doc,
        });
        setSelectedDoc(undefined);
        setDoc(undefined);
    };

    const footerContent = [
        selectedDoc?.id && (
            <Button danger onClick={handleDeleteDoc}>
                {t('delete')}
            </Button>
        ),
        <Button onClick={handleCancel}> {t('cancel')}</Button>,
        <Button onClick={handleOk} type="primary">
            {t('ok')}
        </Button>,
    ];

    const handleSubmit = async () => {
        if (files) {
            let formData = new FormData();
            // @ts-ignore
            formData.append('file', files[0].originFileObj);
            const item = {
                type: messageType,
            };
            formData.append('document', JSON.stringify(item));

            await addDoc(formData);
            setFiles(undefined);
        }
    };

    const handleDocData = (id: number, url: string) => {
        if (selectedDoc?.id === id) {
            setSelectedDoc(undefined);
        } else {
            setSelectedDoc({ id, url });
        }
    };

    useEffect(() => {
        let image =
            data?.data.map((el, index) => {
                return {
                    width: 0,
                    height: 0,
                    src: el.url,
                    id: el.id,
                };
            }) ?? [];
        setImages(image);
    }, [data]);

    const selectStyleHandler = (id: number) => {
        return selectedDoc?.id === id
            ? '15px solid #EEEEEE'
            : '2px solid #EEEEEE';
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <>
            <Modal
                title={`${t(title)}`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={footerContent}
                width={'70%'}
                // style={{ width: '70vw' }}
            >
                <Upload
                    style={{ width: '100%' }}
                    listType="picture-card"
                    fileList={files}
                    multiple={false}
                    onChange={({ fileList }) => setFiles(fileList)}
                    beforeUpload={() => false}
                >
                    <div style={{ width: '70%' }}>
                        <PlusCircleOutlined />
                        <div> {t('upload')}</div>
                    </div>
                </Upload>
                {files?.length! > 0 && (
                    <Row>
                        <Col span={24}>
                            <Button
                                loading={isAddingDoc}
                                style={{ marginBottom: '0.5rem' }}
                                icon={<UploadOutlined />}
                                type="primary"
                                onClick={handleSubmit}
                            >
                                {t('upload')}
                            </Button>
                        </Col>
                    </Row>
                )}
                {(isLoading || isDeleting) && (
                    <Row>
                        <Col span={24} className={classes.spin}>
                            <Spin />
                        </Col>
                    </Row>
                )}
                {messageType == DocumentType.IMAGE && (
                    <Gallery images={images} onSelect={handleSelect} />
                )}
                <Row
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                    ]}
                >
                    {data?.data?.length! > 0 ? (
                        data?.data?.map((el, index) => (
                            <Col className="gutter-row" span={6} key={el.id}>
                                <>
                                    {messageType == DocumentType.VIDEO && (
                                        <video
                                            className={classes.displayTag}
                                            height={100}
                                            width={200}
                                            onClick={(e: any) =>
                                                handleDocData(el?.id, el?.url)
                                            }
                                            controls
                                            style={{
                                                border: selectStyleHandler(
                                                    el?.id,
                                                ),
                                            }}
                                        >
                                            <source src={el?.url} />
                                        </video>
                                    )}

                                    {messageType == DocumentType.DOCUMENT && (
                                        <div
                                            className={`${classes.documentContainer} ${selectedDoc?.id === el.id ? classes.documentContainerSelected : ''}`}
                                        >
                                            <div
                                                className={classes.selectButton}
                                                onClick={() => {
                                                    handleDocData(
                                                        el?.id,
                                                        el?.url,
                                                    );
                                                }}
                                            >
                                                {selectedDoc?.id === el.id ? (
                                                    <CheckCircleFilled
                                                        className={
                                                            classes.checkIcon
                                                        }
                                                        style={{
                                                            color: '#1890ff',
                                                        }}
                                                    />
                                                ) : (
                                                    <CheckCircleOutlined
                                                        className={
                                                            classes.checkIcon
                                                        }
                                                    />
                                                )}
                                            </div>

                                            <div
                                                style={{
                                                    height: 150,
                                                    width: 190,
                                                    overflow: 'hidden',
                                                    border: selectStyleHandler(
                                                        el?.id,
                                                    ),
                                                }}
                                            >
                                                <Document
                                                    file={el?.url}
                                                    onLoadSuccess={
                                                        onDocumentLoadSuccess
                                                    }
                                                >
                                                    <Page
                                                        pageNumber={1}
                                                        width={200}
                                                        height={100}
                                                    />
                                                </Document>
                                            </div>
                                        </div>
                                    )}
                                </>
                            </Col>
                        ))
                    ) : (
                        <Col
                            className="gutter-row"
                            span={24}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <h3>{t('data_not_found')}</h3>
                        </Col>
                    )}
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                    <Col span={18}>
                        <Pagination
                            defaultCurrent={1}
                            current={page}
                            pageSize={pageSize}
                            total={data?.meta.totalItems}
                            pageSizeOptions={[10, 15, 20]}
                            showSizeChanger={false}
                            showLessItems
                            onChange={(p: number, b: any) => {
                                setPageSize(b);
                                setPage(p);
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default UploadModal;
