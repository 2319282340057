import { buildQueries } from '@testing-library/react';
import { api } from '../../../api';
import { sessionSlice } from '../slices/session.slice';
import { MessagesDto, SendMessageDto, SessionsDto } from '../dtos/messages.dto';
import {
    IMessage,
    ISessionMessage,
} from '../interface/session.messages.interface';
import { IUserSessions } from '../interface/user.session.interface';

export const sessionsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<IUserSessions, SessionsDto>({
            query: ({ page, limit }) => {
                let url = `/users?page=${page}&limit=${limit}`;
                return {
                    url: url,
                    method: 'GET',
                };
            },
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(sessionSlice.actions.setUsers(data.data));
            },
            providesTags: ['sessions'],
        }),
        getSessions: builder.query<any, void>({
            query: () => ({
                url: `/sessions`,
                method: 'Get',
            }),
        }),
        getMessages: builder.query<ISessionMessage, MessagesDto>({
            query: ({ id }) => ({
                url: `/sessions/${id}/messages`,
                method: 'GET',
            }),
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
            },
            providesTags: ['SessionChat'],
        }),
        terminateSession: builder.mutation<any, { sessionId: number }>({
            query: ({ sessionId }) => {
                return {
                    url: `/sessions/${sessionId}/terminate`,
                    method: 'GET',
                };
            },
            invalidatesTags: ['sessions'],
        }),
        startChat: builder.mutation<any, { sessionId: number }>({
            query: ({ sessionId }) => {
                return {
                    url: `/sessions/${sessionId}/startChat`,
                    method: 'POST',
                };
            },
        }),
        sendMessage: builder.mutation<IMessage, SendMessageDto>({
            query: (payload) => {
                const { sessionId, type, message } = payload;
                return {
                    url: `/sessions/${sessionId}/sendMessage`,
                    method: 'POST',
                    body: { type, message },
                };
            },
            invalidatesTags: ['SessionChat'],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetMessagesQuery,
    useTerminateSessionMutation,
    useGetSessionsQuery,
    useStartChatMutation,
    useSendMessageMutation,
} = sessionsApi;
