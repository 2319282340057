import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";

export const api = createApi({
  reducerPath: "api",
  keepUnusedDataFor: 5,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      // headers.set("x-app-id", `${process.env.REACT_APP_X_APP_ID}`);
     
      if (endpoint !== "refreshToken") {
        const token = (getState() as RootState).authState?.user?.accessToken;
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);        }
      }
      return headers;
    },
  }),
  tagTypes: ["Workflows", "Queries", "Documents", "Conversations", "Actions", "sessions", "SessionChat"],
  endpoints: () => ({}),
});
