import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, Typography } from 'antd';
import { CustomDivider } from '../custom.divider.component';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n/i18n';
interface Props {
    interactiveList: any;
    type: string;
}
type Direction = 'ltr' | 'rtl';

const InteractiveListComponent: React.FC<Props> = ({
    interactiveList,
    type,
}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <>
            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_list.list_title')}
                name={`${type}RowTitle`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t('form.node_list.title_placeholder')}
                    />
                }
            </Form.Item>
            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_list.list_description')}
                name={`${type}RowDescription`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t('form.node_list.list_description')}
                    />
                }
            </Form.Item>
            <CustomDivider />
            <Typography
                style={{
                    paddingBottom: '10px',
                    fontSize: '16',
                    fontWeight: 'bold',
                    direction: direction,
                }}
            >
                List Items (Max. 10 allowed)
            </Typography>
            <Form.List
                name={`${type}_list`}
                initialValue={interactiveList.rows}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <Row
                                    gutter={4}
                                    style={{ marginRight: "20px", direction: direction }}
                                >
                                    <Col span={4}>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            style={{ marginBottom: '5px' }}
                                            {...restField}
                                            name={[name, 'id']}
                                            rules={[{}]}
                                        >
                                            {
                                                <Input
                                                    style={{ width: '100%' }}
                                                    type={'text'}
                                                    placeholder={t(
                                                        'form.node_list.row_id',
                                                    )}
                                                />
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            style={{ marginBottom: '5px' }}
                                            name={[name, 'title']}
                                            rules={[{}]}
                                        >
                                            {
                                                <Input
                                                    type={'text'}
                                                    placeholder={t(
                                                        'form.node_list.row_title',
                                                    )}
                                                />
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            style={{ marginBottom: '5px' }}
                                            labelAlign="left"
                                            name={[name, 'description']}
                                            rules={[{}]}
                                        >
                                            {
                                                <Input
                                                    type={'text'}
                                                    placeholder={t(
                                                        'form.node_list.description_placeholder',
                                                    )}
                                                    defaultValue={
                                                        interactiveList.listTitle ||
                                                        undefined
                                                    }
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <div style={{ height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <Button disabled={fields.length < 2} onClick={() => remove(name)}>-</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ))}
                        <Form.Item 
                            wrapperCol={{ span: 24 }}
                            style={{ direction: direction }}>
                            <Button
                                style={{ width: "100%"}}
                                type="dashed"
                                disabled={fields.length > 9}
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                {t('form.node_list.add_row')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    );
};

export default InteractiveListComponent;
