import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react';
import { IMessage } from '../../../redux/features/workflows/interfaces/message.interface';
import { useAddWorkflowMutation } from '../../../redux/features/workflows/services/workflows.api';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import { useGetTemplatesQuery } from '../../../redux/features/templates/services/templates.api';
import { Template } from '../../../redux/features/templates/interfaces/template.inteface';

type Props = {
    setShowConvDrawer: (showConvDrawer: boolean) => void;
};
type Direction = 'ltr' | 'rtl';

const ConversationForm: React.FC<Props> = ({ setShowConvDrawer }) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [form] = useForm<IMessage>();
    const [addConv, { isLoading }] = useAddWorkflowMutation();
    const {
        data: templatesData,
        error,
        isLoading: templatesLoading,
    } = useGetTemplatesQuery();
    const [templates, setTemplates] = useState<Template[]>(templatesData || []);

    const onFinish = async (values: any) => {
        const selectedTemplateId = form.getFieldValue('Template');
        await addConv({
            type: parseInt(values.type),
            title: values.title,
            templateId: Number(selectedTemplateId),
        });
        form.resetFields();
        setShowConvDrawer(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);

    useEffect(() => {
        if (templatesData) {
            setTemplates(templatesData);
        }
        if (error) {
            console.error('Error fetching templates:', error);
        }
    });

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    height: '100%',
                    flexDirection: 'column',
                }}
            >
                <div>
                    <Form.Item
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        labelAlign={'left'}
                        style={{ direction: direction, marginBottom: '5px' }}
                        label={t('convo.title')}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: t('convo.title_required'),
                            },
                        ]}
                    >
                        {
                            <Input
                                type={'text'}
                                placeholder={t('convo.title_placeholder')}
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        labelAlign={'left'}
                        name="type"
                        label={t('convo.platform')}
                        rules={[{}]}
                        style={{ direction: direction, marginBottom: '5px' }}
                    >
                        <Select
                            placeholder={t('convo.platform_placeholder')}
                            allowClear
                        >
                            <Option value={'0'}>{t('convo.whatsapp')}</Option>
                            <Option value={'1'}>{t('convo.Instagram')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        labelAlign={'left'}
                        name="Template"
                        label={t('convo.template')}
                        rules={[{}]}
                        style={{ direction: direction }}
                    >
                        <Select
                            placeholder={t('convo.template_placeholder')}
                            allowClear
                        >
                            {templates?.map((template) => (
                                <>
                                    <Option
                                        key={template.id.toString()}
                                        value={template.id.toString()}
                                    >
                                        {template.name}
                                    </Option>
                                </>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item style={{ direction: direction }}>
                        <Button
                            style={{
                                width: '100%',
                                height: '50px',
                                fontSize: '20px',
                                borderRadius: '5px',
                            }}
                            type="primary"
                            loading={isLoading}
                            htmlType="submit"
                        >
                            {t('convo.submit')}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default ConversationForm;
