import React from 'react';
import { LayoutsComponent } from '../../../common/components/material/material.container.component';
import bot from '../../../assets/login_bot.png';

const BotImageContainerComponent = () => {
    const { Box, Grid } = LayoutsComponent;
    return (
        <Grid item md={7} lg={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box
                sx={{
                    backgroundImage: `url(${bot})`,
                    height: '100vh',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                }}
            />
        </Grid>
    );
};

export default BotImageContainerComponent;
