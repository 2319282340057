import React from 'react';
import { Upload, Button, Spin, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useAddDocumentMutation } from '../../../redux/features/workflows/services/workflows.api';
import FloatActionButton from '../../../common/components/float.action.button';
interface UploadSectionProps {
    documentType: number;
    uploadButtonText: string;
    accept?: string;
}

const UploadSection: React.FC<UploadSectionProps> = ({
    documentType,
    uploadButtonText,
    accept,
}) => {
    const [addDoc, { isLoading: isAddingDoc }] = useAddDocumentMutation();

    const uploadHandler = async (options: any) => {
        const { file, onSuccess, onError } = options;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('document', JSON.stringify({ type: documentType }));

        try {
            await addDoc(formData).unwrap();
            message.success('Upload successful');
            onSuccess?.({}, file);
        } catch (error) {
            message.error('Upload failed');
            onError?.(error);
        }
    };

    return (
        <div style={{ paddingBottom: '20px' }}>
            <Upload
                customRequest={uploadHandler}
                showUploadList={false}
                accept={accept}
                disabled={isAddingDoc}
            >
                <FloatActionButton
                    icon={<UploadOutlined style={{ fontSize: '20px' }} />}
                    buttonAction={() => uploadHandler}
                />
            </Upload>
        </div>
    );
};

export default UploadSection;
