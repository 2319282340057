import { api } from '../../../api';
import { IQueryCollection } from '../interfaces/query.collection.interface';
import { QueriesDto } from '../dtos/query.dto';

export const apiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllQueries: builder.query<
            IQueryCollection,
            { page: number; limit: number }
        >({
            query: ({ page, limit }) => {
                let url = `/queries?page=${page}&limit=${limit}`;
                return {
                    url: url,
                    method: 'GET',
                };
            },
        }),
        queries: builder.query<IQueryCollection, QueriesDto>({
            query: ({ page, limit, option, startDate, endDate }) => {
                let url = `/queries?page=${page}&limit=${limit}`;
                if (option != 0) {
                    url = `${url}&option=${option}`;
                }
                if (endDate != null) {
                    url = `${url}&filter.createdAt=$btw:(${startDate},${endDate})`;
                }
                return {
                    url: url,
                    method: 'GET',
                };
            },
            transformResponse: (rawResult: any) => {
                return { data: rawResult.data, meta: rawResult.meta };
            },
            providesTags: ['Queries'],
        }),
        markAsComplete: builder.mutation<
            any,
            {
                queryIds: number[];
                page: number;
                limit: number;
                option?: number;
                startDate?: Date;
                endDate?: Date;
                setCheckedIds: (input: []) => void;
            }
        >({
            query: ({ queryIds, page, limit, option, startDate, endDate }) => {
                return {
                    url: 'queries/completed',
                    method: 'POST',
                    body: { queryIds },
                };
            },
            async onQueryStarted(
                {
                    queryIds,
                    page,
                    limit,
                    option,
                    startDate,
                    endDate,
                    setCheckedIds,
                },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: completedQuery } = await queryFulfilled;
                    setCheckedIds([]);
                    const patchResult = dispatch(
                        api.util.updateQueryData(
                            'queries' as never,
                            {
                                page,
                                limit,
                                option,
                                startDate,
                                endDate,
                            } as never,
                            (draft: IQueryCollection) => {
                                if (completedQuery) {
                                    completedQuery.map((el: any) => {
                                        let index = draft.data.findIndex(
                                            (item: any) => item.id == el.id,
                                        );
                                        if (index != -1) {
                                            draft.data[index] = el;
                                        }
                                    });
                                    return void {
                                        data: draft.data,
                                        meta: draft.meta,
                                    };
                                }
                            },
                        ),
                    );
                    console.log('patchResult', patchResult);
                } catch {}
            },
        }),

        postFeedBack: builder.mutation<
            IQueryCollection,
            {
                id: number;
                feedBack: string;
                page: number;
                limit: number;
                option?: number;
                startDate?: Date;
                endDate?: Date;
            }
        >({
            query: ({
                id,
                feedBack,
                page,
                limit,
                option,
                startDate,
                endDate,
            }) => {
                return {
                    url: `queries/${id}/feedback`,
                    method: 'PATCH',
                    body: { feedback: feedBack },
                };
            },
            async onQueryStarted(
                { id, feedBack, page, limit, option, startDate, endDate },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: queryFeedBack }: any = await queryFulfilled;
                    const patchResult = dispatch(
                        api.util.updateQueryData(
                            'queries' as never,
                            {
                                page,
                                limit,
                                option,
                                startDate,
                                endDate,
                            } as never,
                            (draft: IQueryCollection) => {
                                if (queryFeedBack) {
                                    let index = draft.data.findIndex(
                                        (item: any) => item.id == id,
                                    );
                                    if (index != -1) {
                                        draft.data[index].feedback = feedBack;
                                    }
                                    return void {
                                        data: draft.data,
                                        meta: draft.meta,
                                    };
                                }
                            },
                        ),
                    );
                    console.log('patchResult', patchResult);
                } catch {}
            },
        }),
        getQueryStats: builder.query<any, void>({
            query: () => {
                return {
                    url: 'queries/queryStats',
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useGetAllQueriesQuery,
    useQueriesQuery,
    useMarkAsCompleteMutation,
    usePostFeedBackMutation,
    useGetQueryStatsQuery,
} = apiSlice;
