import { Handle, Position } from 'reactflow';
import {
    FileImageOutlined,
    VideoCameraOutlined,
    FileTextOutlined,
    FilePdfOutlined,
    OrderedListOutlined,
    PushpinOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

const messageTypeColors: { [key: string]: string } = {
    IMAGE: 'yellow',
    VIDEO: 'orange',
    TEXT: 'purple',
    DOCUMENT: 'brown',
    'INTERACTIVE.BUTTON': 'cyan',
    'INTERACTIVE.LIST': 'teal',
    LOCATION: 'lightBlue',
    DEFAULT: 'grey',
};
// @ts-ignore
export const ImageMessageNode = ({ data }) => {
    const preMessageType = data?.message?.preMessageType?.toUpperCase();
    const messageType = data?.message?.type?.toUpperCase();
    const preMessageColor =
        messageTypeColors[preMessageType] || messageTypeColors.DEFAULT;
    const messageColor =
        messageTypeColors[messageType] || messageTypeColors.DEFAULT;
    const isConcluding = data?.message?.isConcluding;

    const truncate = (str: string, length: number) => {
        return str?.length > length ? str?.substring(0, length) + '...' : str;
    };

    return (
        <>
            <Handle position={Position.Bottom} type="target"></Handle>
            {isConcluding ? null : (
                <Handle position={Position.Top} type="source"></Handle>
            )}

            <div
                style={{
                    width: 169,
                    height: 135,
                    overflow: 'hidden',
                    borderRadius: 5,
                    color: 'white',
                    background: '#75757533',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{
                            height: 30,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: '6px 6px 0px 0px',
                            background: '#393535',
                            overflow: 'scroll',
                            fontWeight: 700,
                            fontSize: 10,
                            opacity: isConcluding ? 0.5 : 1,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '7px',
                                }}
                            >
                                {data.message &&
                                    data.message.type === 'text' && (
                                        <FileTextOutlined size={30} />
                                    )}
                                {data.message &&
                                    data.message.type === 'image' && (
                                        <FileImageOutlined size={30} />
                                    )}
                                {data.message &&
                                    data.message.type === 'video' && (
                                        <VideoCameraOutlined size={30} />
                                    )}
                                {data.message &&
                                    data.message.type === 'document' && (
                                        <FilePdfOutlined size={30} />
                                    )}
                                {data.message &&
                                    data.message.type ===
                                        'interactive.list' && (
                                        <OrderedListOutlined size={30} />
                                    )}
                                {data.message &&
                                    data.message.type ===
                                        'interactive.button' && (
                                        <OrderedListOutlined size={30} />
                                    )}
                                {data.message &&
                                    data.message.type === 'location' && (
                                        <PushpinOutlined size={30} />
                                    )}
                                &nbsp;
                            </div>
                            <Tooltip title={data.label}>
                                <div
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                >
                                    {truncate(data.label, 17)}
                                </div>
                            </Tooltip>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                gap: 5,
                                marginRight: '8px',
                            }}
                        >
                            <div
                                style={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: preMessageColor,
                                }}
                            ></div>
                            <div
                                style={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: messageColor,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </>
    );
};
