import React, { useEffect, useState } from 'react';
import { Tabs, Spin, message } from 'antd';
import { DocumentType } from '../workflows/components/upsert.message.component';
import {
    useAddDocumentMutation,
    useGetDocumentsQuery,
} from '../../redux/features/workflows/services/workflows.api';
import UploadSection from './components/uploadSection';
import PreviewSection from './components/previewSection';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
type Direction = 'ltr' | 'rtl';

const Gallery: React.FC = () => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [addDoc, { isLoading: isAddingDoc }] = useAddDocumentMutation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const { t } = useTranslation();
    const {
        data: images,
        isLoading: isLoadingImages,
        isError: isErrorImages,
    } = useGetDocumentsQuery({
        type: DocumentType.IMAGE,
        page: 1,
        limit: 10000,
    });
    const {
        data: videos,
        isLoading: isLoadingVideos,
        isError: isErrorVideos,
    } = useGetDocumentsQuery({
        type: DocumentType.VIDEO,
        page: 1,
        limit: 10000,
    });
    const {
        data: documents,
        isLoading: isLoadingDocuments,
        isError: isErrorDocuments,
    } = useGetDocumentsQuery({
        type: DocumentType.DOCUMENT,
        page: 1,
        limit: 10000,
    });

    const handlePreviewClick = (url: string) => {
        setSelectedItem(url);
    };

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <div>
            <Tabs defaultActiveKey="1" centered direction={direction}>
                <TabPane tab={t('gallery.images')} key="1">
                    <UploadSection
                        documentType={DocumentType.IMAGE}
                        uploadButtonText="Upload Images"
                        accept=".jpg,.jpeg,.png"
                    />
                    {isLoadingImages && <Spin tip="Loading images..." />}
                    {isErrorImages && <div>Error loading images</div>}
                    {!isLoadingImages && !isErrorImages && images && (
                        <PreviewSection
                            items={images.data}
                            type={DocumentType.IMAGE}
                            onPreviewClick={handlePreviewClick}
                            selectedItem={selectedItem}
                        />
                    )}
                </TabPane>
                <TabPane tab={t('gallery.videos')} key="2">
                    <UploadSection
                        documentType={DocumentType.VIDEO}
                        uploadButtonText="Upload Videos"
                        accept=".mp4,.avi"
                    />
                    {isLoadingVideos && <Spin tip="Loading videos..." />}
                    {isErrorVideos && <div>Error loading videos</div>}
                    {!isLoadingVideos && !isErrorVideos && videos && (
                        <PreviewSection
                            items={videos.data}
                            type={DocumentType.VIDEO}
                            onPreviewClick={handlePreviewClick}
                            selectedItem={selectedItem}
                        />
                    )}
                </TabPane>
                <TabPane tab={t('gallery.documents')} key="3">
                    <UploadSection
                        documentType={DocumentType.DOCUMENT}
                        uploadButtonText="Upload Documents"
                        accept=".pdf"
                    />
                    {isLoadingDocuments && <Spin tip="Loading documents..." />}
                    {isErrorDocuments && <div>Error loading documents</div>}
                    {!isLoadingDocuments && !isErrorDocuments && documents && (
                        <PreviewSection
                            items={documents.data}
                            type={DocumentType.DOCUMENT}
                            onPreviewClick={handlePreviewClick}
                            selectedItem={selectedItem}
                        />
                    )}
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Gallery;
