import { Tag, Tooltip } from 'antd';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import classes from './table.module.css';
import { filterData } from './user.queries.helper';

interface RequestDetailsProps {
    request: string[];
    filterMenu: any;
}

const ELEMENT_LENGTH: number = 15;
const RESPONSIVE_WIDTH_POINT: number = 950;
export const RequestDetails = ({
    request,
    filterMenu,
}: RequestDetailsProps) => {
    const [width, height] = useWindowSize();
    return (
        <>
            <div className={classes.ReqDetails}>
                {filterData(request, filterMenu)?.map(
                    (element: any, i: number) => {
                        return (
                            element?.length > 0 && (
                                <Tag
                                    key={i}
                                    style={{
                                        marginBottom: '0.2rem',
                                        width: 'auto',
                                        marginLeft: `${(i + 1) * 8}px`,
                                        border: 'none',
                                    }}
                                    color={'blue'}
                                >
                                    {width < RESPONSIVE_WIDTH_POINT &&
                                    element?.length > ELEMENT_LENGTH ? (
                                        <Tooltip
                                            placement="topRight"
                                            title={element}
                                        >
                                            {element.slice(0, 14) + '...'}
                                        </Tooltip>
                                    ) : (
                                        element
                                    )}
                                </Tag>
                            )
                        );
                    },
                )}
            </div>
        </>
    );
};

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.outerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
