import React, { useEffect, useState } from 'react';
import {
    InputComponents,
    LayoutsComponent,
    IconsComponent,
    FeedbackComponents,
    DataDisplayComponents,
} from '../../common/components/material/material.container.component';
import companyName from '../../assets/company_name.png';
import { useForgotPasswordMutation } from '../../redux/features/auth/services/auth.api';
import { ForgotPasswordDto } from '../../redux/features/auth/dtos/forgot.password.dto';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from '../../redux/store';
import BotImageContainerComponent from './components/bot.image.container.component';

const ForgotPasswordPage = () => {
    const { InputAdornment, Button, OutlinedInput } = InputComponents;
    const { Box, Grid } = LayoutsComponent;
    const { IconButton, Visibility, VisibilityOff } = IconsComponent;
    const { CircularProgress } = FeedbackComponents;
    const { Typography } = DataDisplayComponents;
    const [formData, setFormData] = useState<ForgotPasswordDto>({
        email: '',
    });

    const [
        forgotPassword,
        { isLoading, isError: isAuthError, error: authError, isSuccess },
    ]: any = useForgotPasswordMutation();
    const message = useAppSelector((state) => state.forgotPasswordState.resMessage);
    useEffect(() => {
        isSuccess && message != null && (
            toast.success(message?.message, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            })
        )
    }, [message]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        forgotPassword(formData);
    };
    return (
        <>
            
            <Grid container columnSpacing={2} width="100%">
                <BotImageContainerComponent/>
                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={6}
                    display="flex"
                    alignItems="center"
                >
                    <Box
                        sx={{
                            paddingX: { xs: 8, md: 4, lg: 8 },
                            width: '100%',
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    margin: 'auto',
                                    alignItems: 'flex-end',
                                }}
                            >
                               <img
                            style={{objectFit: 'cover'}}
                                src={companyName}
                                width="203px"
                                height="160px"
                            />
                            </Box>
                            <Typography
                                variant="h5"
                                sx={{ textAlign: 'center' }}
                            >
                                Reset Password
                            </Typography>
                            <Typography
                                variant="body1"
                                color={'grey'}
                                sx={{ textAlign: 'center' }}
                                marginBottom={1.5}
                                marginTop={0.5}
                            >
                              Please enter your email address
                            </Typography>

                            <Box>
                                <Typography>Email</Typography>
                                <OutlinedInput
                                    required
                                    value={formData.email}
                                    sx={{
                                        width: '100%',
                                    }}
                                    onChange={(e) =>
                                        setFormData({ email: e.target.value })
                                    }
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: 'red',
                                    }}
                                >
                                    {authError != undefined &&
                                        authError.data.message}
                                </Typography>
                            </Box>
                            <Box
                                marginTop={10}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                            >
                                <Button
                                    sx={{ width: '100%', height: 50 }}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={false}
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                        />
                                    ) : (
                                        'Reset Password'
                                    )}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
};

export default ForgotPasswordPage;
