import moment from 'moment';
import { filterData } from '../modules/userqueries/user.queries.helper';

export enum QueryStatus {
    'New' = 1,
    'Inprogress...' = 2,
    'Completed' = 3,
}

export function transformCsvData(data: any, filterMenu: any, version: number) {
    return data.map((element: any, index: number) => ({
        Id: element.id,
        Date: moment(element.createdAt).format('DD/MM/YYYY'),
        'Customer Name': element.from.name,
        Request: version > 1 ? element.options : filterData(element.options, filterMenu),
        Status: element.state !== 3 ? 'unRead' : 'Read',
        Mobile: `'${element.from.mobile}'`+'',
        Progress: QueryStatus[element.state],
        Feedback: element.feedback,
    }));
}
