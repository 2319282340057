import {
    Button,
    Form,
    Input,
    Row,
    Col,
    Checkbox,
    Typography,
    Divider,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { IBranch } from '../../../redux/features/workflows/interfaces/branch.interface';
import { useForm } from 'antd/es/form/Form';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { default as classes } from './workflow.components.module.css';
import { ImageMessageNode } from '../nodes/image.message.node';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';

type Props = {
    branch: IBranch | undefined;
    setBranch: (branch: IBranch | undefined) => void;
    setNewBranch: (newBranch: IBranch | undefined) => void;
    setUpdatedBranch: (id: number | null) => void;
    length: number;
    setShowingBranchDrawer: (showingBranchDrawer: boolean) => void;
};
type NodeTitle = {
    label: string;
};

type Direction = 'ltr' | 'rtl';
const UpsertBranchComponent: React.FC<Props> = ({
    branch,
    setBranch,
    setNewBranch,
    setUpdatedBranch,
    length,
    setShowingBranchDrawer,
}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [isDynamic, setIsDynamic] = useState(false);
    const [backLink, setBackLink] = useState(false);
    const [form] = useForm<any>();
    const [toData, setToData] = useState<NodeTitle>({ label: '' });
    const [fromData, setFromData] = useState<NodeTitle>({ label: '' });
    const [conditionValue, setConditionValue] = useState('');
    const onFinish = (values: any) => {
        const temporaryBranch: any = { ...branch };
        if (
            temporaryBranch.condition != values.condition ||
            temporaryBranch.isBackLink != values.isBackLink
        ) {
            setUpdatedBranch(Number(temporaryBranch.id));
        }
        temporaryBranch.condition = values.condition;
        temporaryBranch.isBackLink = values.isBackLink;
        setNewBranch({ ...temporaryBranch });
        form.resetFields();
        setBranch(undefined);
        setShowingBranchDrawer(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        console.log('branch====', branch);
        form.setFieldsValue(branch!);
        setIsDynamic(branch?.condition === '***');
        setBackLink(branch?.isBackLink ?? false);
        setFromData({ label: branch?.from.title ?? '' });
        setToData({ label: branch?.to.title ?? '' });
        setConditionValue(branch?.condition ?? '');
    }, [branch]);

    const isDynamicHandle = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        const branchValue = { ...branch } as IBranch;
        branchValue.condition = checked ? '***' : '';
        setConditionValue(checked ? '***' : '');
        form.setFieldsValue(branchValue!);
        setIsDynamic(checked);
    };
    const onChange = (e: any) => {
        e.preventDefault();
        setConditionValue(e.target.value);
    };
    const isBackLinkHandle = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        const branchValue = { ...branch } as IBranch;
        branchValue.isBackLink = checked;
        form.setFieldsValue(branchValue!);
        setBackLink(checked);
    };
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <Form
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            form={form}
            labelCol={{ span: 8 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div>
                <Form.Item
                    labelAlign="left"
                    label={t('form.branch.condition')}
                    name="condition"
                    rules={[{ message: t('from.branch.condition_required') }]}
                    style={{ marginBottom: 'unset', direction: direction }}
                >
                    {
                        <Input
                            type={'text'}
                            maxLength={3}
                            disabled={isDynamic}
                            placeholder={
                                isDynamic
                                    ? '***'
                                    : t('form.branch.condition_placeholder')
                            }
                            onChange={onChange}
                            defaultValue={branch?.condition}
                        />
                    }
                </Form.Item>
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        direction: direction,
                    }}
                >
                    <Form.Item
                        labelAlign="right"
                        name="isDynamic"
                        style={{
                            marginBottom: 'unset',
                            textAlign: 'right',
                            alignItems: 'right',
                            alignContent: 'right',
                        }}
                    >
                        {
                            <Checkbox
                                name="isDynamic"
                                checked={isDynamic}
                                onChange={isDynamicHandle}
                            >
                                <Typography style={{ paddingRight: '0px' }}>
                                    {`${t('form.branch.dynamic')}`}
                                </Typography>
                            </Checkbox>
                        }
                    </Form.Item>
                    <Form.Item
                        labelAlign="right"
                        name="isBackLink"
                        style={{
                            marginBottom: 'unset',
                            textAlign: 'left',
                            alignItems: 'left',
                            alignContent: 'left',
                        }}
                    >
                        {
                            <Checkbox
                                name="isBackLink"
                                checked={backLink}
                                onChange={isBackLinkHandle}
                            >
                                <Typography style={{ paddingRight: '0px' }}>
                                    {`${t('form.branch.back_link')}`}
                                </Typography>
                            </Checkbox>
                        }
                    </Form.Item>
                </Row>

                <div
                    className={classes.node_container}
                    style={{ direction: direction }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Typography> {`${t('form.branch.from')}`}</Typography>
                        <ImageMessageNode data={fromData} />
                    </div>
                    <div className={classes.divider_line_container}>
                        <div className={classes.divider}></div>
                        {conditionValue != '' ? (
                            <div className={classes.condition_position}>
                                {conditionValue}
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Typography> {`${t('form.branch.to')}`}</Typography>
                        <ImageMessageNode data={toData} />
                    </div>
                </div>
            </div>
            <div className={classes.btn_container}>
                <Form.Item>
                    <Button
                        style={{
                            width: '27vw',
                            height: 50,
                            borderRadius: '5px',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                        className={classes.save_btn}
                        type="primary"
                        htmlType="submit"
                    >
                        {t("save")}
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};

export default UpsertBranchComponent;
