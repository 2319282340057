import { Input } from 'antd';
import axios from 'axios';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from '../layout/mainlayout/main.layout';
import LoginPage from '../modules/login/login.page';
import QueryReplies from '../modules/replies/query.replies';
import UserQueries from '../modules/userqueries/user.queries';
import { WorkflowDetailPage } from '../modules/workflows/workflow.detail.page';
import { WorkflowList } from '../modules/workflows/workflows.list.page';
import { useAppSelector } from '../redux/store';
import UserSessions from '../modules/userSessions/user.sessions';
import ForgotPasswordPage from '../modules/login/forgot.password.page';
import ResetPasswordPage from '../modules/login/reset.password.Page';
import ChatDrawer from '../modules/chat/chat.drawer';
import { DashboardPage } from '../modules/dashboard/dashboard.page';
import Gallery from '../modules/gallery/gallery';

const AppRoutes = () => {
    const user = useAppSelector((state) => state.authState.user);

    function PrivateRoute({ children }: any) {
        if (user) {
            return <MainLayout>{children}</MainLayout>;
        } else {
            return <Navigate to="/" />;
        }
    }

    function PrivateAuthRoute({ children }: any) {
        if (user) {
            return <Navigate to="/dashboard" />;
        } else {
            return children;
        }
    }
    return (
        <Routes>
            <Route>
                <Route
                    path="/"
                    element={
                        <PrivateAuthRoute>
                            {<LoginPage ping={'pong'} />}
                        </PrivateAuthRoute>
                    }
                />
            </Route>
            <Route>
                <Route path="/forgot" element={<ForgotPasswordPage />} />
            </Route>
            <Route>
                <Route
                    path="/change-password"
                    element={<ResetPasswordPage />}
                />
            </Route>
            <Route>
                <Route
                    path="queries"
                    element={
                        <PrivateRoute>
                            <UserQueries />
                        </PrivateRoute>
                    }
                />
            </Route>
            <Route>
                <Route
                    path="/workflow"
                    element={
                        <PrivateRoute>
                            <WorkflowList />
                        </PrivateRoute>
                    }
                />
            </Route>
            <Route>
                <Route
                    path="/sessions"
                    element={
                        <PrivateRoute>
                            <UserSessions />
                        </PrivateRoute>
                    }
                />
            </Route>
            <Route>
                <Route
                    path="/workflow/:id"
                    element={
                        <PrivateRoute>
                            <WorkflowDetailPage />
                        </PrivateRoute>
                    }
                />
            </Route>
            <Route>
                <Route path="/replies" element={<QueryReplies />} />
            </Route>
            <Route>
                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            <DashboardPage />
                        </PrivateRoute>
                    }
                />
            </Route>
            <Route>
                <Route
                    path="/gallery"
                    element={
                        <PrivateRoute>
                            <Gallery />
                        </PrivateRoute>
                    }
                />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
