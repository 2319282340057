import { IMessage } from '../../../redux/features/sessions/interface/session.messages.interface';

type Props = {
    message: IMessage
}

export const ChatImageComponent: React.FC<Props> = ({ message }) => {
    return (
        <div>
            <img src={message.message} style={{ width: "100%" }} />
        </div>
    );
}