import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../interfaces/user.interface";

interface IAuthState {
    user: IUser | undefined;
    token: string | undefined;
    menu: any;
}

const initialState: IAuthState = {
    user: undefined,
    token: undefined,
    menu: undefined,
};

export const authSlice = createSlice({
    initialState,
    name: "authSlice",
    reducers: {
        setUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
        },
        setMenu: (state, action: PayloadAction<any>) => {
            state.menu = action.payload;
        },
        resetAuth: () => {
            console.log("in resetAuth()");
        },
    },
});

export default authSlice.reducer;
