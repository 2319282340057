import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleOutlined,
    InstagramOutlined,
    WhatsAppOutlined,
} from '@ant-design/icons';
import { Card, Spin, Checkbox, Button, MenuProps, Space, Dropdown } from 'antd';
import {
    useActivateWorkflowMutation,
    useDeleteWorkflowMutation,
    useGetWorkflowsQuery,
} from '../../../redux/features/workflows/services/workflows.api';
// import { Table } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import type { ColumnsType } from 'antd/es/table';
import { IWorkflow } from '../../../redux/features/workflows/interfaces/workflow.interface';
import { useNavigate } from 'react-router-dom';
import classes from './table.module.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import i18n from '../../../i18n/i18n';

type Direction = 'ltr' | 'rtl';
const ConversationList = ({}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data, isLoading, refetch } = useGetWorkflowsQuery();

    const [direction, setDirection] = useState<Direction>('ltr');
    const [
        deleteWorkFlow,
        { isLoading: isDeleting, isError: isAuthError, error: authError },
    ]: any = useDeleteWorkflowMutation();
    const [activateConvos, { isLoading: isActivatingConv }] =
        useActivateWorkflowMutation();

    const columns: ColumnsType<IWorkflow> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <a
                    onClick={(e) => {
                        navigate(`/workflow/${record.id}`);
                    }}
                >
                    {text}
                </a>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Active',
            key: 'isActive',
            dataIndex: 'isActive',
            render: (_, { isActive }) => (
                <>
                    {' '}
                    {isActive ? (
                        <CheckCircleOutlined />
                    ) : (
                        <CloseCircleOutlined />
                    )}
                </>
            ),
        },
        {
            title: 'Service',
            key: 'type',
            dataIndex: 'type',
            render: (_, { type }) => (
                <>
                    {' '}
                    {type === 0 ? (
                        <WhatsAppOutlined style={{ fontSize: '15px' }} />
                    ) : (
                        <InstagramOutlined style={{ fontSize: '15px' }} />
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);

    return (
        <>
            <Table
                className={classes.Table}
                style={{ margin: '30px 0 0', direction: direction }}
            >
                <Thead>
                    <Tr>
                        <Th>{t('_deep.mark')}</Th>
                        <Th>{t('_deep.id')}</Th>
                        <Th>{t('_deep.title')}</Th>
                        <Th>{t('_deep.active')}</Th>
                        <Th>{t('_deep.service')}</Th>
                        <Th></Th>
                    </Tr>
                </Thead>

                {data?.length == 0 && !isLoading && (
                    <div className={classes.notFound}>
                        <h2>{t('no_data_yet')}</h2>
                    </div>
                )}

                <Tbody>
                    {data?.map((item: any, i: number) => {
                        const items: MenuProps['items'] = [
                            {
                                key: '1',
                                label: (
                                    <Button
                                        style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                        onClick={() =>
                                            deleteWorkFlow({ id: item.id })
                                        }
                                    >
                                        {t('delete')}
                                    </Button>
                                ),
                            },
                            {
                                key: '2',
                                label: (
                                    <Button
                                        style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                        onClick={() =>
                                            activateConvos({ id: item.id })
                                        }
                                        disabled={item.isActive}
                                    >
                                        {t('activate')}
                                    </Button>
                                ),
                            },
                        ];
                        return (
                            <Tr key={item.id}>
                                <Td>
                                    <Checkbox
                                        checked
                                        disabled={
                                            item.state == 3 || !item?.feedback
                                        }
                                        onChange={(e: any) => {}}
                                    />
                                </Td>
                                <Td
                                    style={{ padding: '5px' }}
                                    onClick={() => {
                                        navigate(`/workflow/${item.id}`);
                                    }}
                                >
                                    <a> {item.id} </a>
                                </Td>
                                <Td>{item.title}</Td>
                                <Td align="left">
                                    {item.isActive ? (
                                        <CheckCircleTwoTone
                                            style={{ fontSize: '17px' }}
                                        />
                                    ) : (
                                        <CloseCircleOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )}
                                </Td>

                                <Td>
                                    {item.type === 0 ? (
                                        <WhatsAppOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <InstagramOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )}
                                </Td>
                                <Td>
                                    <a>
                                        <Space>
                                            <Dropdown
                                                menu={{ items }}
                                                placement="bottomLeft"
                                            >
                                                <Button
                                                    style={{
                                                        fontSize: '20px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        paddingBottom: '15px',
                                                        border: 'none',
                                                    }}
                                                >
                                                    ...
                                                </Button>
                                            </Dropdown>
                                        </Space>
                                    </a>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </>
    );
};

export default ConversationList;
