import axios from 'axios';
import React, { useState } from 'react';
import bot from '../../assets/login_bot.png';
import companyName from '../../assets/company_name.png';
import { Navigate, useNavigate } from 'react-router-dom';
import showNotification from '../../common/components/notifications';
import useAxios from '../../configs/axios.config';
import { AuthenticateDto } from '../../redux/features/auth/dtos/authenticate.dto';
import { useAuthenticateMutation } from '../../redux/features/auth/services/auth.api';
import {
    InputComponents,
    LayoutsComponent,
    IconsComponent,
    FeedbackComponents,
    SurfaceComponents,
    DataDisplayComponents,
} from '../../common/components/material/material.container.component';
import BotImageContainerComponent from './components/bot.image.container.component';


type LoginPageProps = {
    ping: string;
};


const LoginPage: React.FC<LoginPageProps> = (props) => {
    const navigate = useNavigate();
    
    const { InputAdornment, Button, OutlinedInput } = InputComponents;
    const { Box, Grid } = LayoutsComponent;
    const { IconButton, Visibility, VisibilityOff } = IconsComponent;
    const { CircularProgress } = FeedbackComponents;
    const { Typography } = DataDisplayComponents;
    const [
        authenticate,
        { isLoading, isError: isAuthError, error: authError },
    ]: any = useAuthenticateMutation();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState<AuthenticateDto>({
        username: '',
        password: '',
        remember: true,
    });

    const onFinish = async (authDto: AuthenticateDto) => {
        console.log('called', authDto);
        await authenticate(authDto);
    };

    const onFinishFailed = (errorInfo: any) => {};

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log('userName', formData);
        authenticate(formData);
    };

    return (
        <Grid container columnSpacing={2} width="100%">
                <BotImageContainerComponent/>
            <Grid item xs={12} md={5} lg={6} display="flex" alignItems="center">
                <Box sx={{ paddingX: { xs: 8, md: 4, lg: 8 }, width: '100%' }}>
                    <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                margin: 'auto',
                                alignItems: 'flex-end',
                            }}
                        >
                            <img
                            style={{objectFit: 'cover'}}
                                src={companyName}
                                width="193px"
                                height="160px"
                            />
                        </Box>
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>
                            Login
                        </Typography>
                        <Typography
                            variant="body1"
                            color={'grey'}
                            sx={{ textAlign: 'center' }}
                            marginBottom={1}
                            marginTop={0.5}
                        >
                            Sign in and build cool bots
                        </Typography>
                        {isAuthError && (
                            <Typography style={{ color: 'red' }}>
                                {isAuthError}
                            </Typography>
                        )}
                        <Box>
                            <Typography>Email</Typography>
                            <OutlinedInput
                                required
                                sx={{
                                    width: '100%',
                                }}
                                // placeholder="UserName"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        username: e.target.value,
                                    })
                                }
                            />
                        </Box>
                        <Box>
                            <Typography marginTop={3}>Password</Typography>
                            <OutlinedInput
                                required
                                aria-label="passwrod"
                                sx={{
                                    width: '100%',
                                }}
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        password: e.target.value,
                                    })
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'red',
                                }}
                            >
                                {authError != undefined &&
                                    authError.data.message}
                            </Typography>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/forgot');
                                }}
                                variant="text"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                Forgot Password?
                            </Button>
                        </Box>
                        <Box
                            marginTop={10}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                sx={{ width: '100%', height: 50 }}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={false}
                            >
                                {isLoading ? (
                                    <CircularProgress
                                        size={24}
                                        color="inherit"
                                    />
                                ) : (
                                    'Login'
                                )}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
