import * as React from "react";
import { render } from "react-dom";
import useRecorder from "./useRecorder";

import "./styles.css";
import { Button } from "antd";

function Recorder({ setAudio, audio }: any) {
  let [audioURL, isRecording, startRecording, stopRecording, setAudioURL] =
    useRecorder({
      setAudio,
    });
  return (
    <div className="Recorder">
      <audio style={{ width: "100%" }} src={audioURL} controls />
      <p>
        Status :{" "}
        <b>{isRecording ? "Recording..." : audio ? "Recorded" : "Dull"}</b>
      </p>
      <Button type="primary" onClick={startRecording} disabled={isRecording}>
        Start
      </Button>
      <Button
        style={{ marginLeft: "1rem" }}
        type="primary"
        danger
        onClick={stopRecording}
        disabled={!isRecording}
      >
        Stop
      </Button>
      {audio && (
        <Button
          danger
          style={{ marginLeft: "10px" }}
          onClick={() => {
            setAudio(null);
            setAudioURL("");
          }}
        >
          Delete
        </Button>
      )}
    </div>
  );
}

export default Recorder;
