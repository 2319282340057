import { useState } from 'react';
import { IMessage } from '../../../redux/features/sessions/interface/session.messages.interface';

type Props = {
    message: IMessage
}

export const ChatInteractiveListComponent: React.FC<Props> = ({ message }) => {
    const payload = JSON.parse(message.message);

    const [showRows, setShowRows] = useState(false);

    return (
        <div>
            <div>{payload.text}</div>
            <hr style={{ borderWidth: 0.5}} />
            <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                <button style={{ width: "100%" }} onClick={() => setShowRows(!showRows)}>{payload.title}</button>
            </div>
            {showRows && payload.rows.map((row: any) => 
                <div>
                    <div>
                        <b>{row.title}</b>
                    </div>
                    <div style={{ fontSize: 10 }}>{row.description}</div>
                    <hr style={{ borderColor: "#ccc", borderStyle: "dashed", borderWidth: 0.5 }}/>
                </div>
            )}
        </div>
    );
}