import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Gallery, Image as GalleryImage } from 'react-grid-gallery';
import { DocumentType } from '../../workflows/components/upsert.message.component';
import { List } from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PreviewSectionProps {
    items: any[];
    type: number;
    onPreviewClick: (url: string) => void;
    selectedItem: string | null;
}

interface ImageData {
    url: string;
    width?: number;
    height?: number;
    caption?: string;
}
const PreviewSection: React.FC<PreviewSectionProps> = ({
    items,
    type,
    onPreviewClick,
    selectedItem,
}) => {
    const [numPages, setNumPages] = useState<number | null>(null);

    const formattedImages: GalleryImage[] =
        items?.map((img: ImageData) => ({
            src: img?.url,
            thumbnail: img.url,
            thumbnailWidth: img.width,
            thumbnailHeight: img.height,
            width: img.width || 0,
            height: img.height || 0,
            caption: img.caption || '',
        })) || [];

    return (
        <div>
            {type === DocumentType.IMAGE && ( // Image type
                <Gallery images={formattedImages} />
            )}
            {type === DocumentType.VIDEO && ( // Video type
                <div>
                    <List
                        grid={{ gutter: 16, column: 2 }}
                        dataSource={items}
                        renderItem={(item) => (
                            <List.Item>
                                <div>
                                    <video width="100%" controls>
                                        <source
                                            src={item.url}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <div>{item.title}</div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            )}
            {type === DocumentType.DOCUMENT && ( // Document type
                <div>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 2,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 8,
                            xxl: 10,
                        }}
                        dataSource={items}
                        renderItem={(item) => (
                            <List.Item>
                                <div>
                                    <div
                                        style={{
                                            height: 200,
                                            width: 150,
                                            overflow: 'hidden',
                                            border: '2px solid #EEEEEE',
                                        }}
                                    >
                                        <Document
                                            file={item.url as unknown as string}
                                        >
                                            {Array.from(
                                                new Array(numPages),
                                                (el, index) => (
                                                    <Page
                                                        pageNumber={1}
                                                        width={190}
                                                        height={150}
                                                    />
                                                ),
                                            )}
                                        </Document>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            )}
        </div>
    );
};

export default PreviewSection;
