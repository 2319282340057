import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../auth/interfaces/user.interface';

interface SessionsState {
    users: IUser[] | [];
}
const initialState: SessionsState = {
    users: [],
};
export const sessionSlice = createSlice({
    initialState,
    name: 'sessionSlice',
    reducers: {
        setUsers: (state, action: PayloadAction<any[]>) => {
            state.users = action.payload;
        },
    },
});
