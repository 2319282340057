import { Input, Form, Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n/i18n';

interface Props {
    type: string;
    setIsModalOpen: any;
    itemUrl: any;
}

type Direction = 'ltr' | 'rtl';

const DocumentOptionComponent: React.FC<Props> = ({
    type,
    setIsModalOpen,
    itemUrl,
}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <>
            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_document.doc_caption')}
                name={`${type}Caption`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t(
                            'form.node_document.caption_placeholder',
                        )}
                    />
                }
            </Form.Item>

            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={`${t('form.node_document.doc_title')}`}
                name={`${type}Filename`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t('form.node_document.title_caption')}
                        // defaultValue={message?.title || undefined}
                    />
                }
            </Form.Item>

            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_document.doc_link')}
                name={`${type}link`}
                rules={[{}]}
            >
                <>
                    <div
                        style={{
                            height: '80px',
                            width: '100%',
                            // @ts-ignore
                            border: itemUrl === null && '1px solid lightgrey',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {itemUrl === null ? (
                            <Button onClick={() => setIsModalOpen(true)}>
                                +
                            </Button>
                        ) : (
                            <>
                                <div>{itemUrl}</div>
                            </>
                        )}
                    </div>
                    {itemUrl && (
                        <a target="_blank" href={`${itemUrl}`}>
                            <Typography
                                style={{
                                    color: 'blue',
                                    marginTop: '15px',
                                }}
                            >
                                {t('form.node_document.doc_preview')}
                            </Typography>
                        </a>
                    )}
                </>
            </Form.Item>
        </>
    );
};

export default DocumentOptionComponent;
