import axios from "axios";
import { useNavigate } from "react-router-dom";
import { store } from "../redux/store";

const useAxios = () => {
  const navigate = useNavigate();
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BASE_URL,
  };

  let axiosInstance = axios.create(defaultOptions);

  axiosInstance.interceptors.request.use(function (config: any) {
    const token = store?.getState()?.authState?.user?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const responseInterceptor = axiosInstance.interceptors.response.use(
    function (successRes) {
      console.log("successRes", successRes);
      return successRes;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("axiosError", error.response.status);
        localStorage.removeItem("token");
        navigate("/");
      }
      return error;
    }
  );
  return [axiosInstance, responseInterceptor];
};

export default useAxios;
