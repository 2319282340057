import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Modal, Form, Input, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useChangePasswordMutation } from '../../redux/features/auth/services/auth.api';
import { api } from '../../redux/api';
import { useAppDispatch } from '../../redux/store';
import { authSlice } from '../../redux/features/auth/slices/auth.slice';

interface Props {
    open: any;
    setOpen: any;
}
const ChangePasswordPage: React.FC<Props> = ({ open, setOpen }) => {
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [notMatched, setNotMatched] = useState(false)
    const dispatch = useAppDispatch();
    const [
        changePassword,
        { isLoading, isError: isAuthError, error: authError, isSuccess },
    ] = useChangePasswordMutation();
    const hideModal = () => {
        setOpen(false);
        setPassword('')
        setConfirmPassword('')
        setNotMatched(false)
        setPasswordVisible(false)
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (isSuccess) {
            logout();
            hideModal();
        }
    }, [isSuccess]);
    const logout = () => {
        dispatch(api.util.resetApiState());
        dispatch(authSlice.actions.resetAuth());
    };
    const handleChangePassword = () => {
        if (password !== confirmPassword) {
            setNotMatched(true)
            return;
        }
        // hideModal();
        changePassword({ password: password });
    };

    return (
        <Modal
            title="Change Password"
            open={open}
            onOk={handleChangePassword}
            onCancel={hideModal}
            okText="submit"
            cancelText="Cancel"
        >
            {isLoading ? (
                <Spin
                    size="large"
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '50%',
                        left: '45%',
                    }}
                />
            ) : (
                <div>
                    <Input
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ marginBottom: '16px' }}
                    />
                     <Input
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder="confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={{ marginBottom: '5px' }}
                        addonAfter={
                            <Space>
                                <div
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: 'pointer',  }}
                                >
                                    {passwordVisible ? (
                                        <EyeTwoTone style={{color: 'black'}}/>
                                    ) : (
                                        <EyeInvisibleOutlined style={{color: '#42a5f5'}} />
                                    )}
                                </div>
                            </Space>
                        }
                    />
                 <div> { notMatched && <Typography style={{color: 'red', fontSize: 14}}>Passwords are not matched</Typography>}</div>
                </div>
            )}
        </Modal>
    );
};

export default ChangePasswordPage;
