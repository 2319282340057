import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IForgotPassword } from "../interfaces/forgot.password.interface";


interface IForgotPasswordState {
    resMessage: IForgotPassword | undefined;
}
const initialState : IForgotPasswordState = {
    resMessage: undefined
}

export const forgotPasswordSlice = createSlice({
    initialState,
    name: "forgotPasswordSlice",
    reducers: {
        setResponse: (state, action: PayloadAction<IForgotPassword>) => {
            console.log("action.payload====", action.payload)
            state.resMessage = action.payload;
        },
    },
});