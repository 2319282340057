import React from 'react';
import { Button } from 'antd';

interface Props {
    icon: any;
    buttonAction: () => void;
}
const FloatActionButton: React.FC<Props> = ({ icon, buttonAction }) => {
    return (
        <Button
            onClick={buttonAction}
            style={{
                position: 'fixed',
                bottom: 130,
                height: 50,
                width: 50,
                right: 25,
                zIndex: 1,
            }}
            type="primary"
            shape="circle"
            icon={icon}
        />
    );
};

export default FloatActionButton;
