import { Card, Popconfirm } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../redux/api';
import { authSlice } from '../../redux/features/auth/slices/auth.slice';
import { useAppDispatch } from '../../redux/store';
import classes from './logout.module.css';
import { Button, Popover, ConfigProvider, Avatar, Modal, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ChangePasswordPage from '../login/change.password.page';
import { useTranslation } from 'react-i18next';

export const LogoutPopOver = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const text = <span>{t("profile")}</span>;
    const [visible, setVisible] = useState<boolean>(false);
    const logout = () => {
        dispatch(api.util.resetApiState());
        dispatch(authSlice.actions.resetAuth());
    };

    const hideModal = () => {
        setOpen(false);
    };

    const showModal = () => {
        setOpen(true);
    };
    const content = (
        <div>
            <p>
                <a href="#" onClick={showModal}>
                    {t("change_password")}
                </a>
            </p>
            <p>
                <a href="#" onClick={() => logout()}>
                    {t("logout")}
                </a>
            </p>
        </div>
    );
    const buttonWidth = 80;
    return (
        <>
            <ConfigProvider>
                <div className="demo">
                    <div
                        style={{
                            marginInlineStart: buttonWidth + 4,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Popover
                            placement="topLeft"
                            title={text}
                            content={content}
                            trigger="click"
                        >
                            <Avatar
                                size={50}
                                icon={<UserOutlined />}
                                style={{ float: 'right', marginTop: '5px' }}
                            />
                        </Popover>
                    </div>
                </div>
            </ConfigProvider>
            <ChangePasswordPage open={open} setOpen={setOpen} />
        </>
    );
};
