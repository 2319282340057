import { IMessage } from '../../../redux/features/sessions/interface/session.messages.interface';

type Props = {
    message: IMessage
}

export const ChatTextComponent: React.FC<Props> = ({ message }) => {
    return (
        <div>
            {message.message.split("\n").map((line: string) => 
                <p style={{fontWeight: line.startsWith("*") && line.endsWith("*") ? "bold" : "normal"}}>
                    {line.replaceAll("*", "")}
                </p>
            )}
        </div>
    );
}