import { authSlice } from '../slices/auth.slice';
import { forgotPasswordSlice } from '../slices/fotgot.password.slice';
import { api } from '../../../api';
import { AuthenticateDto } from '../dtos/authenticate.dto';
import { IUser } from '../interfaces/user.interface';
import { IForgotPassword } from '../interfaces/forgot.password.interface';
import {
    ResetPasswordDto,
    ForgotPasswordDto,
} from '../dtos/forgot.password.dto';
import {
    ChangePasswordDto,
    IChangePassword,
} from '../dtos/change.password.dto';

export const apiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        authenticate: builder.mutation<IUser, AuthenticateDto>({
            query: (payload) => ({
                url: '/auth/login',
                method: 'POST',
                body: payload,
            }),
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                try {
                    console.log('queryFulfilled:', queryFulfilled);
                    const { data } = await queryFulfilled;
                    console.log('data:', data);
                    console.log('d.a.t.a', data);
                    dispatch(authSlice.actions.setMenu(data.company.menu));
                    dispatch(authSlice.actions.setUser(data));
                } catch (e) {}
            },
        }),
        forgotPassword: builder.mutation<IForgotPassword, ForgotPasswordDto>({
            query: (payload) => ({
                url: '/auth/password/forgot',
                method: 'POST',
                body: payload,
            }),
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                try {
                    console.log('queryFulfilled:', queryFulfilled);
                    const { data } = await queryFulfilled;
                    console.log('data:', data);
                    console.log('d.a.t.a', data);
                    dispatch(forgotPasswordSlice.actions.setResponse(data));
                } catch (e) {}
            },
        }),
        resetPassword: builder.mutation<IForgotPassword, ResetPasswordDto>({
            query: (payload) => {
                console.log('payload====', payload);
                return {
                    url: '/auth/password/reset',
                    method: 'PATCH',
                    body: payload,
                };
            },
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                try {
                    console.log('queryFulfilled:', queryFulfilled);
                    const { data } = await queryFulfilled;
                    console.log('data:', data);
                    console.log('d.a.t.a', data);
                    // dispatch(forgotPasswordSlice.actions.setResponse(data));
                } catch (e) {}
            },
        }),
        changePassword: builder.mutation<IChangePassword, ChangePasswordDto>({
            query: (payload) => {
                console.log('payload====', payload);
                return {
                    url: '/auth/change-password',
                    method: 'PATCH',
                    body: payload,
                };
            },
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                try {
                    console.log('queryFulfilled:', queryFulfilled);
                    const { data } = await queryFulfilled;
                    console.log('data:', data);
                    console.log('d.a.t.a', data);
                    // dispatch(forgotPasswordSlice.actions.setResponse(data));
                } catch (e) {}
            },
        }),
    }),
});

export const {
    useChangePasswordMutation,
    useAuthenticateMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = apiSlice;
