import classes from './dashboard.module.css';
import StatCard from './components/stat.card';
import { useGetQueryStatsQuery } from '../../redux/features/queries/services/queries.api';
import { useEffect, useState } from 'react';
import { useGetUsersStatsQuery } from '../../redux/features/users/services/users.api';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

interface Stats {
    month: Array<object>;
    today: Array<object>;
    week: Array<object>;
}
interface QueryStats {
    month: {
        new: [];
        completed: [];
    };
    today: {
        new: [];
        completed: [];
    };
    week: {
        new: [];
        completed: [];
    };
}

type Direction = 'ltr' | 'rtl';
export const DashboardPage = () => {
    const {
        data: queryStatsData,
        error: queryError,
        isLoading: queryLoading,
    } = useGetQueryStatsQuery();
    const {
        data: userStatsData,
        error: userError,
        isLoading: userLoading,
    } = useGetUsersStatsQuery();
    const [direction, setDirection] = useState<Direction>('ltr');
    const { t } = useTranslation();

    const [userStatData, setUserStat] = useState<Stats | null>(userStatsData);
    const [queryStatData, setQueryStat] = useState<QueryStats | null>(
        queryStatsData,
    );
    useEffect(() => {
        if (userStatsData) {
            setUserStat(userStatsData);
        }
        if (queryStatsData) {
            setQueryStat(queryStatsData);
        }
    }, [userStatsData, queryStatsData]);

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <>
            <div className={classes['stats']} style={{ direction: direction }}>
                <div className={classes['cards']}>
                    <p className={classes['stats-line']}>
                        {t('dashboard.user_Stats')}
                    </p>
                    <StatCard
                        title={t('dashboard.today')}
                        entity={t('dashboard.users')}
                        count={userStatData ? userStatData.today.length : 0}
                        usersLoading={userLoading}
                    />
                    <StatCard
                        title={t('dashboard.week')}
                        entity={t('dashboard.users')}
                        count={userStatData ? userStatData.week.length : 0}
                        usersLoading={userLoading}
                    />
                    <StatCard
                        title={t('dashboard.month')}
                        entity={t('dashboard.users')}
                        count={userStatData ? userStatData.month.length : 0}
                        usersLoading={userLoading}
                    />
                </div>
            </div>
            <div className={classes['stats']} style={{ direction: direction }}>
                <div className={classes['cards']}>
                    <p className={classes['stats-line']}>
                        {t('dashboard.query_Stats')}
                    </p>
                    <StatCard
                        title={t('dashboard.today')}
                        entity={t('dashboard.queries')}
                        count={0}
                        newQ={queryStatData?.today.new.length ?? 0}
                        completedQ={queryStatData?.today.completed.length ?? 0}
                        queryLoading={queryLoading}
                        isQuery={true}
                    />
                    <StatCard
                        title={t('dashboard.week')}
                        entity={t('dashboard.queries')}
                        count={0}
                        newQ={queryStatData?.week.new.length ?? 0}
                        completedQ={queryStatData?.week.completed.length ?? 0}
                        queryLoading={queryLoading}
                        isQuery={true}
                    />
                    <StatCard
                        title={t('dashboard.month')}
                        entity={t('dashboard.queries')}
                        count={0}
                        newQ={queryStatData?.month.new.length ?? 0}
                        completedQ={queryStatData?.month.completed.length ?? 0}
                        queryLoading={queryLoading}
                        isQuery={true}
                    />
                </div>
            </div>
        </>
    );
};
