import React, { useEffect, useState } from 'react';
import {
    InputComponents,
    LayoutsComponent,
    IconsComponent,
    FeedbackComponents,
    DataDisplayComponents,
} from '../../common/components/material/material.container.component';
import companyName from '../../assets/company_name.png';
import { useResetPasswordMutation } from '../../redux/features/auth/services/auth.api';
import { ResetPasswordDto } from '../../redux/features/auth/dtos/forgot.password.dto';
import { useNavigate } from 'react-router-dom';
import BotImageContainerComponent from './components/bot.image.container.component';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const { InputAdornment, Button, OutlinedInput } = InputComponents;
    const { Box, Grid } = LayoutsComponent;
    const { IconButton, Visibility, VisibilityOff } = IconsComponent;
    const { CircularProgress } = FeedbackComponents;
    const { Typography } = DataDisplayComponents;
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState<ResetPasswordDto>({
        password: '',
    });
    const [passwordMatch, setPasswordMatch] = useState('');
    const [confirmPassword, setConfirmPassword] = useState({
        confirmPassword: '',
    });

    const [
        changePassword,
        { isLoading, isError: isAuthError, error: authError, isSuccess },
    ] = useResetPasswordMutation();

    useEffect(() => {
        isSuccess && navigate('/');
    }, [isSuccess]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if(confirmPassword.confirmPassword === newPassword.password) {
        changePassword(newPassword);
        } else {
            setPasswordMatch("Password not match")
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }; 

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const newPasswordHandler = (e: any) => {
        passwordMatch != '' && setPasswordMatch('')
        setNewPassword({ password: e.target.value })
    };

    const confirmPasswordHandler = (e: any) => {
        passwordMatch != '' && setPasswordMatch('')
        setConfirmPassword({confirmPassword: e.target.value})
    };
    return (
        <Grid container columnSpacing={2} width="100%">
            <BotImageContainerComponent/>
            <Grid item xs={12} md={5} lg={6} display="flex" alignItems="center">
                <Box sx={{ paddingX: { xs: 8, md: 4, lg: 8 }, width: '100%' }}>
                    <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                margin: 'auto',
                                alignItems: 'flex-end',
                            }}
                        >
                           <img
                            style={{objectFit: 'cover'}}
                                src={companyName}
                                width="203px"
                                height="160px"
                            />
                        </Box>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'center' }}
                            marginBottom={2}
                        >
                            Change Password
                        </Typography>
                        <Box>
                            <Typography>New Password</Typography>
                            <OutlinedInput
                                required
                                sx={{
                                    width: '100%',
                                }}
                                id="newPasswordInput"
                                type={showPassword ? 'text' : 'password'}
                                onChange={newPasswordHandler}
                            />
                        </Box>
                        <Box>
                            <Typography marginTop={3}>
                                Confirm Password
                            </Typography>
                            <OutlinedInput
                                required
                                aria-label="passwrod"
                                sx={{
                                    width: '100%',
                                }}
                                id="confirmPasswordInput"
                                type={showPassword ? 'text' : 'password'}
                                onChange={confirmPasswordHandler}
                                
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color:'red'
                                }}
                            >
                                {passwordMatch}
                                
                            </Typography>
                        </Box>
                        <Box
                            marginTop={10}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                sx={{ width: '100%', height: 50 }}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={false}
                            >
                                {isLoading ? (
                                    <CircularProgress
                                        size={24}
                                        color="inherit"
                                    />
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ResetPasswordPage;
