import { Drawer } from 'antd';
import React from 'react';

type DrawerProps = {
    title: string;
    showing: boolean;
    onClose: () => void;
    children: any;
    placement?: any;
};

export const DrawerComp: React.FC<DrawerProps> = ({
    title,
    showing,
    onClose,
    children,
    placement,
}) => {
    return (
        <Drawer
            size={"large"}
            title={title}
            headerStyle={{ fontSize: "20" }}
            width={'30vw'}
            placement={placement ? placement : 'right'}
            // maskClosable={false}
            onClose={onClose}
            visible={showing}
        >
            {children}
        </Drawer>
    );
};
