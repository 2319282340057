import { Avatar, MenuProps } from 'antd';
import './main.layout.css';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import defaultLogo from '../../assets/logo.png';
import { useAppSelector } from '../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { LogoutPopOver } from '../../modules/logout/logout.page';
import LanguageSelector from '../../i18n/languageSelector';
import { useTranslation } from 'react-i18next';
// import classes from "./main.module.css";

const { Header, Content, Footer } = Layout;
const items1: MenuProps['items'] = [].map((key) => ({
    key,
    label: `nav ${key}`,
}));
// const { Title, Text } = Typography;

const MainLayout: React.FC<any> = (props: any) => {
    const { t } = useTranslation(); // Use the useTranslation hook
    const location = useLocation();
    const navigate = useNavigate();
    const [visibleProfile, setVisibleProfile] = useState<boolean>(false);
    const user = useAppSelector((state) => state.authState.user);
    let menuItems = [
        { key: '/queries', label: t('queries') },
        { key: '/workflow', label: t('workflows') },
        { key: '/sessions', label: t('sessions') },
        { key: '/gallery', label: t('gallery.gallery') },
    ];
    return (
        <Box>
            <Layout
                style={{
                    height: '100vh',
                }}
            >
                <Box>
                    <Header className="header">
                        <Box className="company-name-container">
                            <Typography
                                className="company-name"
                                onClick={() => navigate('/dashboard')}
                            >
                                {user && user.company && user.company.name}
                            </Typography>
                        </Box>
                        <LogoutPopOver />
                        <Box
                            className="select-language"
                            fontStyle={{
                                direction: `${t('direction')}`,
                            }}
                        >
                            <Box className="label">
                                {t('select_language')}:{' '}
                            </Box>
                            <LanguageSelector />
                        </Box>
                        <Menu
                            className="menu"
                            mode="horizontal"
                            defaultSelectedKeys={['Queries']}
                            selectedKeys={[location.pathname]}
                            items={menuItems}
                            onClick={(e: any) => navigate(e.key)}
                        />
                    </Header>
                </Box>
                <Content
                    onClick={() => {
                        setVisibleProfile(false);
                    }}
                    style={{ padding: '' }}
                >
                    <Layout
                        className="site-layout-background"
                        style={{ padding: '', backgroundColor: 'white' }}
                    >
                        <Content
                            style={{ minHeight: 280, paddingBottom: '80px' }}
                        >
                            {props.children}
                        </Content>
                    </Layout>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    Octek (Pvt) Ltd ©2022
                </Footer>
            </Layout>
        </Box>
    );
};

export default MainLayout;
