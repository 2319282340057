import { notification } from "antd";

interface notificationProps {
    desc: string;
    type: string;
    message?: string;
}

const showNotification = ({ desc, type, message }: notificationProps) => {
    notification.open({
        key: desc,
        message: message ? message : type,
        description: desc,
        type: type == "error" ? "error" : "success",
        onClick: () => {
            console.log("Notification Clicked!");
        },
        style: { width: "100%" },
    });
};

export default showNotification;
