import {
    Button,
    Form,
    Input,
    Radio,
    RadioChangeEvent,
    Select,
    Divider,
    Typography,
    Row,
    Col,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CloseOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { IAction } from '../../../redux/features/workflows/interfaces/action.interface';
import {
    IMessage,
    LocationFields,
} from '../../../redux/features/workflows/interfaces/message.interface';
import UploadModal from './upload.modal';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { default as classes } from './workflow.components.module.css';
const { TextArea } = Input;
const { Option } = Select;
import { MentionsInput, Mention } from 'react-mentions';
import defaultInputStyles from './default.input.styles';
import mentionDefaultStyles from './mention.default.styles';
import InteractiveListComponent from './NodeTypes/interactive.list.option.component';
import { CustomDivider } from './custom.divider.component';
import LocationOptionComponent from './NodeTypes/location.option.component';
import ButtonsFormComponent from './NodeTypes/interactive.buttons.option.component';
import DocumentOptionComponent from './NodeTypes/document.option.component';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import i18n from '../../../i18n/i18n';

type Props = {
    message: IMessage | undefined;
    setMessage: (message: IMessage | undefined) => void;
    setNewNode: (newNode: IMessage) => void;
    setUpdatedNode: (updatedNode: IMessage) => void;
    length: number;
    setShowingMessageDrawer: (showingMessageDrawer: boolean) => void;
    actions: IAction[];
    showingMessageDrawer: boolean;
};

export enum DocumentType {
    IMAGE,
    VIDEO,
    TEXT,
    DOCUMENT,
    INTERACTIVE_BUTTONS,
    INTERACTIVE_LIST,
    LOCATION,
}

export enum NodePosition {
    STARTING = 1,
    CENTERED,
    ENDING,
}

export enum ActionTime {
    Leave = 0,
    Load,
}

interface interactiveButtons {
    title: string;
    // preMessageType_title: string;
    // postMessageType_title: string;
    buttons: ButtonsList[];
}

interface ButtonsList {
    id: string;
    title: string;
}
export interface InteractiveListItems {
    listTitle: string;
    description: string;
    rows: interactiveRowItem[];
}

export interface interactiveRowItem {
    id: string;
    title: string;
    description: string;
}

enum MessageTypes {
    preMessage = 'preMessageType',
    postMessage = 'postMessageType',
}

const InteractiveListInitialState = {
    listTitle: '',
    description: '',
    rows: [{ id: '', title: '', description: '' }],
};

const InteractiveButtonsInitialState = {
    title: '',
    buttons: [{ id: '', title: '' }],
};

type Direction = 'ltr' | 'rtl';

const UpsertMessageComponent: React.FC<Props> = ({
    message,
    setMessage,
    setNewNode,
    setUpdatedNode,
    length,
    setShowingMessageDrawer,
    actions,
    showingMessageDrawer,
}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [form] = useForm<any>();
    const [modalTitle, setModalTitle] = useState<string>('');
    const [actionId, setActionId] = useState<number | null>(null);
    const [preMessageType, setPreMessageType] = useState<number | undefined>(
        undefined,
    );
    const [postMessageType, setPostMessageType] = useState<number | undefined>(
        undefined,
    );
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isInvalidOption, setIsInvalidOption] = useState<boolean>(false);
    const [preDoc, setPreDoc] = useState<any>(undefined);
    const [postDoc, setPostDoc] = useState<any>(undefined);
    const [preItemURL, setPreItemURL] = useState<any>();
    const [postItemUrl, setPostItemUrl] = useState<any>();
    const [nodePositionValue, setNodePositionValue] = useState<NodePosition>(
        NodePosition.CENTERED,
    );
    const [selectedActionTime, setSelectedActionTime] = useState(
        ActionTime.Leave,
    );
    const [preInteractiveButtons, setPreInteractiveButtons] = useState<
        interactiveButtons | undefined
    >(InteractiveButtonsInitialState);
    const [postInteractiveButtons, setPostInteractiveButtons] = useState<
        interactiveButtons | undefined
    >(InteractiveButtonsInitialState);
    const [interactiveList, setInteractiveList] =
        useState<InteractiveListItems>(InteractiveListInitialState);
    const [postInteractiveList, setPostInteractiveList] =
        useState<InteractiveListItems>(InteractiveListInitialState);
    const clearStates = () => {
        setModalTitle('');
        setActionId(null);
        setPreMessageType(undefined);
        setPostMessageType(undefined);
        setIsModalOpen(false);
        setIsInvalidOption(false);
        setPreDoc(undefined);
        setPostDoc(undefined);
        setNodePositionValue(NodePosition.CENTERED);
    };
    const messageTypes = [
        { key: 'text', title: t('form.type_text') },
        { key: 'image', title: t('form.type_image') },
        { key: 'video', title: t('form.type_video') },
        { key: 'document', title: t('form.type_document') },
        { key: 'interactive.button', title: t('form.type_button') },
        { key: 'interactive.list', title: t('form.type_list') },
        { key: 'location', title: t('form.type_location') },
    ];
    const mentions = [
        { id: '1', display: 'John Doe' },
        { id: '2', display: 'Jane Smith' },
        { id: '3', display: 'Raja Zeeshan' },
        { id: '4', display: 'Rana Azeem' },
        { id: '5', display: 'Jane Smith' },
        { id: '6', display: 'Lara' },
    ];

    const onChangeRadioButton = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setNodePositionValue(e.target.value);
    };

    function getMessageType(key: any): DocumentType | undefined {
        switch (key) {
            case 'text':
                return DocumentType.TEXT;
            case 'image':
                return DocumentType.IMAGE;
            case 'video':
                return DocumentType.VIDEO;
            case 'document':
                return DocumentType.DOCUMENT;
            case 'interactive.button':
                return DocumentType.INTERACTIVE_BUTTONS;
            case 'interactive.list':
                return DocumentType.INTERACTIVE_LIST;
            case 'location':
                return DocumentType.LOCATION;
            default:
                return undefined;
        }
    }
    const messageTypeColors: { [key: string]: string } = {
        IMAGE: 'yellow',
        VIDEO: 'orange',
        TEXT: 'purple',
        DOCUMENT: 'brown',
        'INTERACTIVE.BUTTON': 'cyan',
        'INTERACTIVE.LIST': 'teal',
        LOCATION: 'lightBlue',
        DEFAULT: 'grey',
    };

    const setFormFields = (
        type: DocumentType,
        typeName: string,
        dataString: any,
    ) => {
        let jsonObject: any;
        try {
            jsonObject = JSON.parse(dataString);
        } catch (error) {
            console.error('JSON parsing error:', error);
        }
        if (type === DocumentType.INTERACTIVE_BUTTONS) {
            form.setFieldsValue({
                [`${typeName}_title`]: jsonObject.text,
            });
            MessageTypes.preMessage == typeName
                ? setPreInteractiveButtons(jsonObject)
                : setPostInteractiveButtons(jsonObject);
        } else if (type === DocumentType.LOCATION) {
            form.setFieldsValue({
                [`${typeName}Lat`]: jsonObject.latitude,
                [`${typeName}Lon`]: jsonObject.longitude,
                [`${typeName}Cap`]: jsonObject.name,
                [`${typeName}Address`]: jsonObject.address,
            });
        } else if (type === DocumentType.INTERACTIVE_LIST) {
            console.log('message-types====', jsonObject.title);
            form.setFieldsValue({
                [`${typeName}RowTitle`]: jsonObject.title,
                [`${typeName}RowDescription`]: jsonObject.text,
            });
            MessageTypes.preMessage == typeName
                ? setInteractiveList(jsonObject)
                : setPostInteractiveList(jsonObject);
        } else if (type === DocumentType.DOCUMENT) {
            form.setFieldsValue({
                [`${typeName}Caption`]: jsonObject.caption,
                [`${typeName}Filename`]: jsonObject.filename,
                [`${typeName}link`]: jsonObject.link,
            });
            typeName == MessageTypes.preMessage
                ? setPreItemURL(jsonObject.link)
                : setPostItemUrl(jsonObject.link);
        }
    };

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    useEffect(() => {
        if (message) {
            form.setFieldsValue(message);
            message.preMessageType == 'image' ||
            message.preMessageType == 'video'
                ? setPreItemURL(message.preMessage)
                : setPreItemURL(null);

            message.type == 'image' || message.type == 'video'
                ? setPostItemUrl(message.message)
                : setPostItemUrl(null);

            const preMessageType = getMessageType(message.preMessageType);
            setPreMessageType(preMessageType);

            if (
                preMessageType == DocumentType.INTERACTIVE_BUTTONS ||
                preMessageType == DocumentType.LOCATION ||
                preMessageType == DocumentType.INTERACTIVE_LIST ||
                preMessageType == DocumentType.DOCUMENT
            ) {
                setFormFields(
                    preMessageType,
                    'preMessageType',
                    message.preMessage,
                );
            }
            const postMessageType = getMessageType(message.type);
            setPostMessageType(postMessageType);
            if (
                postMessageType == DocumentType.INTERACTIVE_BUTTONS ||
                postMessageType == DocumentType.LOCATION ||
                postMessageType == DocumentType.INTERACTIVE_LIST ||
                postMessageType == DocumentType.DOCUMENT
            ) {
                setFormFields(
                    postMessageType,
                    'postMessageType',
                    message.message,
                );
            }
            setSelectedActionTime(message.actionTime!);
            setActionId(message.action?.id!);
            setIsInvalidOption(message.key === 'invalid.option');
            setNodePositionValue(
                message.isGround0
                    ? NodePosition.STARTING
                    : message.isConcluding
                      ? NodePosition.ENDING
                      : NodePosition.CENTERED,
            );
        } else {
            form.setFieldsValue({
                title: undefined,
                preMessageType: undefined,
                preMessage: undefined,
                type: undefined,
                message: undefined,
                actionTime: ActionTime.Leave,
            });
            setPreItemURL(null);
            setPostItemUrl(null);
            clearStates();
        }
    }, [message]);

    const getDataObject = (messageType: any, type: string, value: any) => {
        let result: string = '';
        if (type === 'document') {
            let document = {
                caption:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeCaption
                        : value.postMessageTypeCaption,
                filename:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeFilename
                        : value.postMessageTypeFilename,
                link:
                    messageType === MessageTypes.preMessage
                        ? preItemURL
                        : postItemUrl,
            };
            result = JSON.stringify(document);
        } else if (type === 'interactive.button') {
            let buttons = {
                text:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageType_title
                        : value.postMessageType_title,
                buttons:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageType_buttons
                        : value.postMessageType_buttons,
            };
            result = JSON.stringify(buttons);
        } else if (type === 'interactive.list') {
            let list = {
                title:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeRowTitle
                        : value.postMessageTypeRowTitle,
                text:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeRowDescription
                        : value.postMessageTypeRowDescription,
                rows:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageType_list
                        : value.postMessageType_list,
            };
            result = JSON.stringify(list);
        } else if (type === 'location') {
            let location = {
                latitude:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeLat
                        : value.postMessageTypeLat,
                longitude:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeLon
                        : value.postMessageTypeLon,
                name:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeCap
                        : value.postMessageTypeCap,
                address:
                    messageType === MessageTypes.preMessage
                        ? value.preMessageTypeAddress
                        : value.postMessageTypeAddress,
            };
            result = JSON.stringify(location);
        } else {
            messageType === MessageTypes.postMessage &&
                (result =
                    postItemUrl != null || postItemUrl != undefined
                        ? postItemUrl
                        : value.message);
            messageType !== MessageTypes.postMessage &&
                (result =
                    preItemURL != null || preItemURL != undefined
                        ? preItemURL
                        : value.preMessage);
        }

        return result;
    };

    const onFinish = (values: any) => {
        const nodeData = {
            id: message ? message.id : length,
            key:
                nodePositionValue === NodePosition.STARTING
                    ? 'ground.0'
                    : isInvalidOption
                      ? 'invalid.option'
                      : `_${values?.title}_`,
            title: values?.title,
            preMessage: getDataObject(
                MessageTypes.preMessage,
                values.preMessageType,
                values,
            ),
            preMessageType: values.preMessageType,
            message: getDataObject(
                MessageTypes.postMessage,
                values.type,
                values,
            ),
            type: values.type,
            anonymousMessage: '',
            position: { x: 0, y: 0 },
            isGround0: nodePositionValue === NodePosition.STARTING,
            isConcluding: nodePositionValue === NodePosition.ENDING,
            actionTime: values.actionTime,
            action: actionId ? { id: actionId } : null,
        };
        if (message) {
            setUpdatedNode(nodeData);
        } else {
            setNewNode(nodeData);
        }
        setMessage(undefined);
        form.resetFields();
        clearStates();
        setPreItemURL(null);
        setPostItemUrl(null);
        setShowingMessageDrawer(false);
    };

    useEffect(() => {
        if (preDoc) {
            form.setFieldValue('preMessage', preDoc.url);
            setPreItemURL(preDoc.url);
        }
    }, [preDoc]);

    useEffect(() => {
        if (postDoc) {
            form.setFieldValue('message', postDoc.url);
            setPostItemUrl(postDoc.url);
        }
    }, [postDoc]);

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const selectionTypeHandler = (title: string, type: string) => {
        setModalTitle(title);

        let messageType: number = -1;
        if (type === 'text') {
            messageType = DocumentType.TEXT;
        } else if (type === 'image') {
            messageType = DocumentType.IMAGE;
        } else if (type === 'video') {
            messageType = DocumentType.VIDEO;
        } else if (type === 'document') {
            messageType = DocumentType.DOCUMENT;
        } else if (type === 'interactive.button') {
            messageType = DocumentType.INTERACTIVE_BUTTONS;
        } else if (type === 'interactive.list') {
            messageType = DocumentType.INTERACTIVE_LIST;
        } else if (type === 'location') {
            messageType = DocumentType.LOCATION;
        }
        if (title === 'PreMessage') {
            clearFields(['preMessage']);
            form.setFieldValue('preMessageType', type);
            setPreMessageType(messageType);
            setPreItemURL(null);
        } else {
            form.setFieldValue('type', type);
            clearFields(['message']);
            setPostMessageType(messageType);
            setPostItemUrl(null);
        }
        // if (type && type === 'image' || type == 'video' ) setIsModalOpen(true);
    };

    const selectAction = (e: any) => {
        setActionId(e);
    };
    const selectActionTime = (e: any) => {
        console.log('values====', e);

        setSelectedActionTime(e === 0 ? ActionTime.Leave : ActionTime.Load);
    };

    useEffect(() => {
        if (!showingMessageDrawer) {
            setPreItemURL(null);
            setPostItemUrl(null);
            clearStates();
            form.resetFields();
            form.setFieldValue('actionTime', ActionTime.Leave);
        }
    }, [showingMessageDrawer]);

    const clearFields = (fields: string[]) => {
        fields.forEach((field) => {
            form.setFieldValue(field, undefined);
        });
    };

    const removeMediaHandler = (messageType: string) => {
        const fieldsToClear =
            messageType === 'preMessage'
                ? ['preMessage', 'preMessageType']
                : ['type', 'message'];
        clearFields(fieldsToClear);
        if (messageType == 'preMessage') {
            setPreDoc(undefined);
            setPreItemURL(null);
        } else {
            setPostDoc(undefined);
            setPostItemUrl(null);
        }
    };

    const displayTransform = (id: any, display: any) => {
        return `#${display}#`;
    };

    const contentHandler = (
        type: number,
        value: string = 'sss',
        messageType: string,
    ) => {
        if (type === DocumentType.TEXT) {
            //here, handle logic when text is pasted by the user.
            return (
                <MentionsInput value={value} style={defaultInputStyles}>
                    <Mention
                        trigger="#"
                        style={mentionDefaultStyles}
                        displayTransform={displayTransform}
                        onAdd={(id, display) => {
                            console.log('values====', id, display);
                        }}
                        data={mentions.map((mention) => ({
                            id: mention.id,
                            display: mention.display,
                        }))}
                        renderSuggestion={(
                            suggestion,
                            search,
                            highlightedDisplay,
                        ) => (
                            <>
                                <div>{highlightedDisplay}</div>
                            </>
                        )}
                    />
                </MentionsInput>
            );
        } else if (type === DocumentType.IMAGE || type == DocumentType.VIDEO) {
            return (
                <div>
                    {value ? (
                        <>
                            <div>
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '80px',
                                        width: '135px',
                                        borderRadius: '13px',
                                    }}
                                >
                                    {type === DocumentType.VIDEO ? (
                                        <video
                                            style={{
                                                height: 'inherit',
                                                borderRadius: '5px',
                                                width: 'inherit',
                                                objectFit: 'cover',
                                            }}
                                            controls
                                        >
                                            <source src={value} />
                                        </video>
                                    ) : (
                                        <img
                                            src={value}
                                            style={{
                                                objectFit: 'inherit',
                                                borderRadius: '5px',
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    )}
                                    <CloseOutlined
                                        size={25}
                                        style={{
                                            position: 'absolute',
                                            top: -3,
                                            right: -8,
                                            backgroundColor: 'lightgrey',
                                            padding: 2,
                                            borderRadius: '13px',
                                        }}
                                        onClick={() => {
                                            removeMediaHandler(messageType);
                                        }}
                                    />
                                </div>
                                <a target="_blank" href={`${value}`}>
                                    <Typography
                                        style={{
                                            color: 'blue',
                                            marginTop: '15px',
                                        }}
                                    >
                                        Preview
                                    </Typography>
                                </a>
                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                // position: 'relative',
                                height: '80px',
                                width: '100%',
                                // borderRadius: '13px',
                                border: '1px solid lightgrey',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button onClick={() => setIsModalOpen(true)}>
                                +
                            </Button>
                        </div>
                    )}
                    {/* )} */}
                </div>
            );
        }
    };

    const onChangeInvalidOption = (e: CheckboxChangeEvent) => {
        setIsInvalidOption(e.target.checked);
    };
    const returnType = (type: string, color: string): React.ReactElement => {
        return (
            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <div
                    style={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: color,
                    }}
                ></div>
                <div>{type}</div>
            </div>
        );
    };
    return (
        <>
            <UploadModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setMessageType={
                    modalTitle === 'PreMessage'
                        ? setPreMessageType
                        : setPostMessageType
                }
                title={modalTitle}
                resetFields={clearFields}
                fieldNames={
                    modalTitle === 'PreMessage' ? ['preMessageType'] : ['type']
                }
                messageType={
                    modalTitle === 'PreMessage'
                        ? preMessageType!
                        : postMessageType!
                }
                doc={modalTitle === 'PreMessage' ? preDoc : postDoc}
                setDoc={modalTitle === 'PreMessage' ? setPreDoc : setPostDoc}
            />
            <Form
                form={form}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    height: '100%',
                    flexDirection: 'column',
                }}
            >
                <div>
                    <Form.Item
                        style={{ marginBottom: '15px', direction: direction }}
                        labelAlign="left"
                        label={t('form.title')}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: t('form.required_input'),
                            },
                        ]}
                    >
                        {
                            <Input
                                type={'text'}
                                placeholder={t('form.input_placeholder')}
                                defaultValue={message?.title || undefined}
                            />
                        }
                    </Form.Item>
                    <CustomDivider />
                    <Typography
                        style={{
                            paddingBottom: '8px',
                            fontWeight: 'bold',
                            direction: direction,
                        }}
                    >
                        {`${t('form.preceding_message')}`}
                    </Typography>
                    <Form.Item
                        labelAlign="left"
                        name="preMessageType"
                        label={t('form.type')}
                        rules={[{}]}
                        style={{ marginBottom: '5px', direction: direction }}
                    >
                        <Select
                            placeholder={t('form.pre_messsage_placeholder')}
                            defaultValue={message?.preMessageType}
                            onChange={(e) =>
                                selectionTypeHandler('PreMessage', e)
                            }
                            allowClear
                        >
                            {messageTypes.map(
                                (type) => (
                                    (
                                        <Select.Option value={type.key}>
                                            {returnType(
                                                type?.title,
                                                messageTypeColors[
                                                    type.key?.toLocaleUpperCase()
                                                ],
                                            )}
                                        </Select.Option>
                                    )
                                ),
                            )}
                        </Select>
                    </Form.Item>
                    {preMessageType === DocumentType.INTERACTIVE_BUTTONS && (
                        <ButtonsFormComponent
                            type="preMessageType"
                            initialValues={preInteractiveButtons}
                        />
                    )}
                    {preMessageType === DocumentType.LOCATION && (
                        <LocationOptionComponent
                            type="preMessageType"
                            location={message?.location}
                        />
                    )}
                    {preMessageType === DocumentType.INTERACTIVE_LIST && (
                        <InteractiveListComponent
                            interactiveList={interactiveList}
                            type="preMessageType"
                        />
                    )}
                    {preMessageType === DocumentType.DOCUMENT && (
                        <DocumentOptionComponent
                            type={MessageTypes.preMessage}
                            setIsModalOpen={setIsModalOpen}
                            itemUrl={preItemURL}
                        />
                    )}
                    {(preMessageType === DocumentType.IMAGE ||
                        preMessageType === DocumentType.VIDEO ||
                        preMessageType === DocumentType.TEXT) && (
                        <Form.Item
                            labelAlign="left"
                            label={t('form.message')}
                            name="preMessage"
                            rules={[
                                {
                                    message: t('form.pre_message_type'),
                                },
                            ]}
                            style={{
                                marginBottom: '15px',
                                direction: direction,
                            }}
                        >
                            {contentHandler(
                                preMessageType!,
                                preItemURL,
                                'preMessage',
                            )}
                        </Form.Item>
                    )}
                    <CustomDivider />
                    <Typography
                        style={{
                            paddingBottom: '10px',
                            fontSize: '16',
                            fontWeight: 'bold',
                            direction: direction,
                        }}
                    >
                        {`${t('form.message')}`}
                    </Typography>
                    <Form.Item
                        labelAlign="left"
                        name="type"
                        label={t('form.type')}
                        required={true}
                        style={{ marginBottom: '5px', direction: direction }}
                        rules={[
                            {
                                required: true,
                                message: t('form.post_message_type'),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t('form.post_message_placeholder')}
                            onChange={(e) =>
                                selectionTypeHandler('PostMessage', e)
                            }
                            allowClear
                            defaultValue={message?.type}
                        >
                            {messageTypes.map((type) => (
                                <Select.Option value={type.key}>
                                    {returnType(
                                        type?.title,
                                        messageTypeColors[
                                            type.key?.toLocaleUpperCase()
                                        ],
                                    )}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {postMessageType === DocumentType.INTERACTIVE_BUTTONS && (
                        <ButtonsFormComponent
                            type="postMessageType"
                            initialValues={postInteractiveButtons}
                        />
                    )}
                    {postMessageType === DocumentType.DOCUMENT && (
                        <DocumentOptionComponent
                            type={MessageTypes.postMessage}
                            setIsModalOpen={setIsModalOpen}
                            itemUrl={postItemUrl}
                        />
                    )}
                    {postMessageType === DocumentType.INTERACTIVE_LIST && (
                        <InteractiveListComponent
                            interactiveList={postInteractiveList}
                            type="postMessageType"
                        />
                    )}
                    {postMessageType === DocumentType.LOCATION && (
                        <LocationOptionComponent
                            type="postMessageType"
                            location={message?.location}
                        />
                    )}
                    {(postMessageType === DocumentType.IMAGE ||
                        postMessageType === DocumentType.VIDEO ||
                        postMessageType === DocumentType.TEXT) && (
                        <Form.Item
                            labelAlign="left"
                            label={t('form.message')}
                            name="message"
                            required={true}
                            style={{
                                marginBottom: '15px',
                                direction: direction,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: t('form.post_message_required'),
                                },
                            ]}
                        >
                            {contentHandler(
                                postMessageType!,
                                postItemUrl,
                                'postMessage',
                            )}
                        </Form.Item>
                    )}
                    <CustomDivider />
                    <Typography
                        style={{
                            paddingBottom: '10px',
                            fontSize: '16',
                            fontWeight: 'bold',
                            direction: direction,
                        }}
                    >
                        {`${t('form.node_position')}`}
                    </Typography>
                    <Form.Item
                        labelAlign="left"
                        label={t('form.starting')}
                        style={{ marginBottom: '0px', direction: direction }}
                        rules={[{}]}
                    >
                        <Checkbox
                            disabled={true}
                            checked={message?.isGround0}
                        />
                    </Form.Item>
                    <Form.Item
                        labelAlign="left"
                        label={t('form.is_concluding')}
                        style={{ direction: direction }}
                        rules={[{}]}
                    >
                        <Checkbox
                            onChange={(e) =>
                                setNodePositionValue(
                                    e.target.checked
                                        ? NodePosition.ENDING
                                        : NodePosition.CENTERED,
                                )
                            }
                            checked={nodePositionValue === NodePosition.ENDING}
                        />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item style={{ width: '100%' }} wrapperCol={{}}>
                        <Button
                            style={{
                                borderRadius: '5px',
                                width: '100%',
                                fontSize: 20,
                                height: 50,
                            }}
                            type="primary"
                            htmlType="submit"
                        >
                            {`${t('save')}`}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default UpsertMessageComponent;
