import React, { useEffect, useState } from 'react';
import {
    useGetSessionsQuery,
    useGetUsersQuery,
    useSendMessageMutation,
    useStartChatMutation,
    useTerminateSessionMutation,
} from '../../redux/features/sessions/services/sessions.api';
import {
    format,
    isToday,
    isYesterday,
    isWithinInterval,
    isThisYear,
    subDays,
} from 'date-fns';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { DrawerComp } from '../../common/components/drawer/drawer.component';
import ChatDrawer from '../chat/chat.drawer';
import { useDispatch } from 'react-redux';
import { api } from '../../redux/api';
import { Typography, Checkbox, Spin, Pagination, Button } from 'antd';
import classes from '../userqueries/table.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { Input } from 'react-chat-elements';
import {
    ISession,
    IUser,
} from '../../redux/features/auth/interfaces/user.interface';
import { DocumentType } from '../workflows/components/upsert.message.component';
import { SendMessageDto } from '../../redux/features/sessions/dtos/messages.dto';

enum StatusMap {
    Active = 1,
    Inactive = 0,
}

type Direction = 'ltr' | 'rtl';

const UserSessions = () => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [message, setMessage] = useState('');
    const [displayReply, setDisplayReply] = useState(false);
    const [clickedSession, setClickedSession] = useState<any>();
    const dispatch = useDispatch();
    const headers = ['Mark', 'Date', 'Mobile', 'Status', 'Email'];
    //api queries and mutations
    const {
        data: users,
        error,
        isLoading,
    } = useGetUsersQuery({ page: page, limit: pageSize });
    const { data: sessions, isLoading: isSessionLoading } =
        useGetSessionsQuery();
    const [terminateSession, { isLoading: terminating, isError }] =
        useTerminateSessionMutation();
    const [
        sendMessage,
        { isLoading: sendingMessage, isError: errorInSendingMessage },
    ] = useSendMessageMutation();
    const [startChat, { isLoading: starting }] = useStartChatMutation();
    const [showingChatDrawer, setShowingChatDrawer] = useState(false);
    const [sessionID, setSessionId] = useState<number>(0);

    const getState = (status: number) => {
        return status == StatusMap.Active ? 'Active' : 'Inactive';
    };

    const formatDate = (dateInString: any) => {
        const date = new Date(dateInString);
        const today = new Date();
        if (isToday(date)) {
            return `Today, ${format(date, 'hh:mm:ss a')}`;
        } else if (isYesterday(date)) {
            return `Yesterday, ${format(date, 'hh:mm:ss a')}`;
        } else if (
            isWithinInterval(date, { start: subDays(today, 6), end: today })
        ) {
            return format(date, 'iiii, hh:mm:ss a');
        } else if (isThisYear(date)) {
            return format(date, 'd/MM/yyyy, hh:mm:ss a');
        } else {
            return format(date, 'd/MM/yyyy, hh:mm:ss a');
        }
    };

    useEffect(() => {
        dispatch(api.util.invalidateTags(['sessions']));
    }, []);

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);

    useEffect(() => {
        if (sessions) {
            const sessionIds: any = findSessionIds();
            const session = sessionIds.filter(
                (session: any) => session.id == sessionID,
            );
            setClickedSession(session);
        }
    }, [sessionID]);
    const findSessionIds = () => {
        return users?.data?.map((user: any) => user?.sessions[0]);
    };
    const acceptInvitation = async (sessionId: number) => {
        try {
            await startChat({ sessionId: sessionId });
            setDisplayReply(true);
            setClickedSession((prev: any) =>
                prev.map((session: any) =>
                    session.id === sessionId
                        ? { ...session, state: 1 }
                        : session,
                ),
            );
        } catch (error) {
            console.error('Failed to start or join chat:', error);
        }
    };

    const sendMessageHandler = async (payload: SendMessageDto) => {
        await sendMessage(payload);
        setMessage('');
    };
    return (
        <div style={{ direction: direction }}>
            <DrawerComp
                title={''}
                showing={showingChatDrawer}
                onClose={() => {
                    setShowingChatDrawer(false);
                    dispatch(api.util.invalidateTags(['sessions']));
                    dispatch(api.util.invalidateTags(['SessionChat']));
                }}
            >
                <ChatDrawer sessionId={sessionID} />

                {clickedSession?.[0]?.state == 1 ? (
                    <Input
                        placeholder={`${t('reply_here')}`}
                        maxHeight={30}
                        onChange={(e: any) => {
                            setMessage(e.target.value);
                        }}
                        value={message}
                        rightButtons={
                            <Button
                                color="white"
                                onClick={() =>
                                    sendMessageHandler({
                                        sessionId: sessionID,
                                        type: 'text',
                                        message: message,
                                    })
                                }
                                disabled={message ? false : true}
                            >
                                {t('send')}
                            </Button>
                        }
                    />
                ) : (
                    <Button onClick={() => acceptInvitation(sessionID)}>
                        {t('start_chat')}
                    </Button>
                )}
            </DrawerComp>
            <Table className={classes.Table} style={{ margin: '30px 0 0' }}>
                <Thead>
                    <Tr>
                        <Th>{t('_deep.mark')}</Th>
                        <Th>{t('_deep.date')}</Th>
                        <Th>{t('_deep.mobile')}</Th>
                        <Th>{t('_deep.status')}</Th>
                        <Th>{t('_deep.email')}</Th>
                        <Th>{t('_deep.actions')}</Th>
                    </Tr>
                </Thead>

                {isLoading && (
                    <Spin
                        size="large"
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            top: '50%',
                            left: '45%',
                        }}
                    />
                )}

                {users?.data?.length == 0 && !isLoading && (
                    <div className={classes.notFound}>
                        <h2>No sessions Yet</h2>
                    </div>
                )}

                <Tbody>
                    {users?.data?.map((user: any, i: number) => {
                        return (
                            <Tr key={user.id}>
                                <Td>
                                    <Checkbox
                                        checked
                                        disabled
                                        onChange={(e: any) => {}}
                                    />
                                </Td>
                                <Td style={{ padding: '10px' }}>
                                    <a
                                        onClick={() => {
                                            setSessionId(
                                                user.sessions?.[0]?.id,
                                            );
                                            setShowingChatDrawer(true);
                                        }}
                                    >
                                        {user.sessions?.[0]?.createdAt &&
                                            formatDate(
                                                user.sessions?.[0]?.createdAt,
                                            )}
                                    </a>
                                </Td>
                                <Td> {user.mobile}</Td>
                                <Td align="left">
                                    <Typography>
                                        {getState(user.sessions[0].status)}
                                    </Typography>
                                </Td>
                                <Td>
                                    {user.email == null ? 'N/A' : user.email}
                                </Td>
                                <Td>
                                    {user.sessions[0].status ? (
                                        <Button
                                            onClick={() =>
                                                terminateSession({
                                                    sessionId:
                                                        user.sessions[0].id,
                                                })
                                            }
                                        >
                                            {t('terminate')}
                                        </Button>
                                    ) : (
                                        ``
                                    )}
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            {users?.meta?.totalItems! > pageSize && (
                <Pagination
                    defaultCurrent={1}
                    pageSize={pageSize}
                    total={users?.meta?.totalItems}
                    pageSizeOptions={[5, 10, 20]}
                    style={{ float: 'right', marginTop: '0.5rem' }}
                    showLessItems
                    onChange={(p: number, b: any) => {
                        setPageSize(b);
                        setPage(p);
                    }}
                />
            )}
        </div>
    );
};

export default UserSessions;
