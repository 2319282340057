import i18n from 'i18next';
import { Translation, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { queries } from '@testing-library/react';

i18n
    // detect user language
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    queries: 'Queries',
                    workflows: 'Workflows',
                    sessions: 'Sessions',
                    _deep: {
                        mark: 'Mark',
                        date: 'Date',
                        applicant_name: 'Applicant Name',
                        request: 'Request',
                        mobile: 'Mobile',
                        status: 'Status',
                        progress: 'Progess',
                        feedback: 'Feedback',
                        email: 'Email',
                        actions: 'Actions',
                        service: 'Service',
                        active: 'Active',
                        title: 'Title',
                        id: 'Id',
                        time: 'Time',
                    },
                    downloadCSV: 'Download CSV',
                    profile: 'Profile',
                    change_password: 'Change Password',
                    logout: 'Logout',
                    reset: 'Reset',
                    cancel: 'Cancel',
                    give_feedback: 'Give your feedback!',
                    feedback: 'Feedback...',
                    ok: 'Ok',
                    start_date: 'Start date',
                    end_date: 'End date',
                    select_language: 'Select language',
                    direction: 'ltr',
                    terminate: 'Terminate',
                    update_node: 'Update Node',
                    add_new_node: 'Add New Node',
                    discard: 'Discard',
                    save: 'Save',
                    unsaved_changes: 'Unsaved Changes',
                    form: {
                        is_concluding: 'Concluding?',
                        node_position: 'Node Position',
                        title: 'Title',
                        preceding_message: 'Preceding Message',
                        type: 'Type',
                        required_input: 'Please input your title!',
                        input_placeholder: 'Title shown on your workflow',
                        pre_messsage_placeholder: 'Select pre message type',
                        type_text: 'Text',
                        type_image: 'Image',
                        type_video: 'Video',
                        type_document: 'Document',
                        type_button: 'Reply Buttons',
                        type_list: 'Interactive List',
                        type_location: 'Location',
                        message: 'Message',
                        pre_message_type: 'Please input your Pre Message Type!',
                        post_message_type: 'Please Select the message type!',
                        post_message_placeholder:
                            'Select a option and change input text above',
                        post_message_required: 'Please input your Post Message',
                        actions: 'Actions',
                        action: 'action',
                        select_action_placeholder: 'Select an action',
                        on: 'On',
                        on_action_required: 'Please select an action time!',
                        leave: 'Leave',
                        load: 'Load',
                        starting: 'Starting?',
                        centered: 'Centered',
                        ending: 'Ending',
                        invalid_option: 'Invalid Option',
                        branch: {
                            condition: 'Condition',
                            condition_required: 'Please input your condition!',
                            condition_placeholder: 'Condition: Example 0',
                            dynamic: 'Dynamic',
                            back_link: 'Back Link',
                            from: 'From',
                            to: 'To',
                            branch: 'Branch',
                        },
                        node_button: {
                            title: 'Title',
                            title_placeholder: 'Please enter Title here...',
                            title_id: 'id',
                            missing_id: 'Missing first name',
                            title_title: 'title',
                            missing_title: 'Missing last name',
                            add_button: 'Add Button',
                        },
                        node_document: {
                            doc_caption: 'Caption',
                            caption_placeholder: 'Please enter caption here...',
                            doc_title: 'Title',
                            title_caption: 'Please enter fileName here...',
                            doc_link: 'Link',
                            doc_preview: 'Preview',
                        },
                        node_list: {
                            list_title: 'Title',
                            title_placeholder: 'Title',
                            list_description: 'List Description',
                            description_placeholder: 'Description',
                            row: 'row:',
                            row_id: 'ID',
                            row_title: 'Title',
                            add_row: 'Add Row',
                        },
                        node_location: {
                            coordinates: 'Coordinates:',
                            latitude: 'Latitude',
                            latitude_placehodlder:
                                'Please enter latitude here...',
                            longitude: 'Longitude',
                            longitude_placehodlder:
                                'Please enter longitude here...',
                            caption: 'Caption',
                            caption_placeholder: 'Caption of the location',
                            address: 'Address',
                            address_placeholder: 'Address',
                        },
                    },
                    PreMessage: 'PreMessage',
                    PostMessage: 'PostMessage',
                    delete: 'Delete',
                    activate: 'Activate',
                    upload: 'Upload',
                    data_not_found: 'Not Data found',
                    convo: {
                        add_conversation: 'Add Conversation',
                        title: 'Title',
                        title_placeholder: 'Enter title',
                        title_required: 'Please input conversation Title!',
                        platform: 'Platform',
                        platform_placeholder: 'Select platform',
                        whatsapp: 'Whatsapp',
                        Instagram: 'Instagram',
                        submit: 'Save',
                        template: 'Template',
                        template_placeholder: 'Select template',
                    },
                    no_data_yet: 'No Data Yet',
                    send: 'Send',
                    start_chat: 'Start chat',
                    reply_here: 'Reply here...',
                    duplicate: 'Duplicate',
                    dashboard: {
                        today: 'Today',
                        week: 'This week',
                        month: 'This month',
                        user_Stats: 'User Stats',
                        query_Stats: 'Query Stats',
                        new: 'New',
                        completed: 'Completed',
                        queries: 'Queries',
                        users: 'Users',
                        total: 'Total',
                    },
                    gallery: {
                        gallery: 'Gallery',
                        images: 'Images',
                        videos: 'Videos',
                        documents: 'Documents',
                    },
                },
            },
            ar: {
                translation: {
                    queries: 'الاستفسارات',
                    workflows: 'سير العمل',
                    sessions: 'جلسات',
                    _deep: {
                        mark: 'علامة',
                        date: 'تاريخ',
                        applicant_name: 'اسم التطبيق',
                        request: 'طلب',
                        mobile: 'متحرك',
                        status: 'حالة',
                        progress: 'تقدم',
                        feedback: 'تعليق',
                        email: 'بريد إلكتروني',
                        actions: 'أفعال',
                        service: 'خدمة',
                        active: 'نشيط',
                        title: 'عنوان',
                        id: 'معرف',
                        time: 'وقت',
                    },
                    downloadCSV: 'تحميل CSV',
                    profile: 'الملف الشخصي',
                    change_password: 'تغيير كلمة المرور',
                    logout: 'تسجيل خروج',
                    reset: 'إعادة تعيين',
                    cancel: 'إلغاء',
                    give_feedback: 'إعطاء ملاحظاتك!',
                    feedback: '...تغذية راجعة',
                    ok: 'نعم',
                    start_date: 'تاريخ البداية',
                    end_date: 'تاريخ الانتهاء',
                    select_language: 'حدد اللغة',
                    direction: 'rtl',
                    terminate: 'فصل من العمل',
                    update_node: 'عقدة التحديث',
                    add_new_node: 'إضافة عقدة جديدة',
                    discard: 'تخلص',
                    save: 'حفظ',
                    unsaved_changes: 'التغييرات غير المحفوظة',
                    form: {
                        title: 'عنوان',
                        preceding_message: 'رسالة ما قبل',
                        type: 'نوع',
                        required_input: 'الرجاء إدخال العنوان الخاص بك!',
                        input_placeholder: 'الرجاء إدخال العنوان هنا...',
                        pre_messsage_placeholder: 'حدد نوع الرسالة المسبقة',
                        type_text: 'النص',
                        type_image: 'الصورة',
                        type_video: 'فيديو',
                        type_document: 'وثيقة',
                        type_button: 'زر',
                        type_list: 'القائمة',
                        type_location: 'موقع',
                        message: 'رسالة',
                        pre_message_type:
                            'الرجاء إدخال نوع الرسالة المسبقة الخاصة بك!',
                        post_message_type: 'الرجاء تحديد نوع الرسالة!',
                        post_message_placeholder:
                            'حدد خيارًا وقم بتغيير نص الإدخال أعلاه',
                        post_message_required:
                            'الرجاء إدخال الرسالة الأخيرة الخاصة بك',
                        actions: 'تصرفات',
                        action: 'الإجراء',
                        select_action_placeholder: 'حدد إجراءً',
                        on: 'على',
                        on_action_required: 'الرجاء تحديد وقت الإجراء!',
                        leave: 'يترك',
                        load: 'حمولة',
                        starting: 'بدءا',
                        centered: 'تتمحور',
                        ending: 'إنهاء',
                        invalid_option: 'خيار غير صحيح',
                        branch: {
                            condition: 'حالة',
                            condition_required: 'الرجاء إدخال حالتك!',
                            condition_placeholder: 'الشرط: مثال 0',
                            dynamic: 'ديناميكي',
                            back_link: 'رابط العودة',
                            from: 'من عند',
                            to: 'الى',
                            branch: 'فرع',
                        },
                        node_button: {
                            title: 'عنوان',
                            title_placeholder: 'الرجاء إدخال العنوان هنا...',
                            title_id: 'معرف',
                            missing_id: 'الاسم الأول المفقود',
                            title_title: 'عنوان',
                            missing_title: 'الاسم الأخير مفقود',
                            add_button: 'إضافة زر',
                        },
                        node_document: {
                            doc_caption: 'تعليق',
                            caption_placeholder:
                                'الرجاء إدخال التسمية التوضيحية هنا...',
                            doc_title: 'عنوان',
                            title_caption: 'الرجاء إدخال اسم الملف هنا...',
                            doc_link: 'حلقة الوصل',
                            doc_preview: 'معاينة',
                        },
                        node_list: {
                            list_title: 'عنوان',
                            title_placeholder: 'الرجاء إدخال العنوان هنا...',
                            list_description: 'وصف',
                            row: 'صف:',
                            row_id: 'معرف',
                            row_title: 'عنوان',
                            add_row: 'أضف صفًا',
                        },
                        node_location: {
                            latitude: 'خط العرض',
                            latitude_placeholder:
                                'الرجاء إدخال خط العرض هنا...',
                            longitude: 'خط الطول',
                            longitude_placeholder:
                                'الرجاء إدخال خط الطول هنا...',
                            caption: 'تعليق',
                            caption_placeholder:
                                'الرجاء إدخال التسمية التوضيحية هنا...',
                            address: 'عنوان',
                            address_placeholder: 'الرجاء إدخال العنوان هنا...',
                        },
                    },
                    PreMessage: 'رسالة مسبقة',
                    PostMessage: 'الرسالة السابقة',
                    delete: 'حذف',
                    activate: 'تفعيل',
                    upload: 'رفع',
                    data_not_found: 'لم يتم العثور على بيانات',
                    convo: {
                        add_conversation: 'إضافة المحادثة',
                        title: 'عنوان',
                        title_placeholder: 'الرجاء إدخال العنوان هنا...',
                        title_required: 'الرجاء إدخال عنوان المحادثة!',
                        platform: 'منصة',
                        platform_placeholder: 'اختر المنصة',
                        whatsapp: 'واتس اب',
                        Instagram: 'انستغرام',
                        submit: 'إرسال',
                        template: 'نموذج',
                        template_placeholder: 'حدد القالب',
                    },
                    no_data_yet: 'لا توجد بيانات حتى الآن',
                    send: 'إرسال',
                    start_chat: 'ابدأ الدردشة',
                    reply_here: '...الرد هنا',
                    duplicate: 'ينسخ',
                    dashboard: {
                        today: 'اليوم',
                        week: 'هذا الأسبوع',
                        month: 'هذا الشهر',
                        user_Stats: 'إحصائيات المستخدمين',
                        query_Stats: 'إحصائيات الاستفسارات',
                        new: 'جديد',
                        completed: 'مكتمل',
                        queries: 'استفسارات',
                        users: 'المستخدمون',
                        total: 'المجموع',
                    },
                    gallery: {
                        gallery: 'معرض',
                        images: 'صور',
                        videos: 'فيديوهات',
                        documents: 'مستندات',
                    },
                },
            },
        },
    });

export default i18n;
