import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authSlice } from "./features/auth/slices/auth.slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "./api";
import { workflowSlice } from "../modules/workflows/slice/workflow.slice";
import { sessionSlice } from "./features/sessions/slices/session.slice";
import { forgotPasswordSlice } from "./features/auth/slices/fotgot.password.slice";

export const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    authState: authSlice.reducer,
    workflowState: workflowSlice.reducer,
    sessionState: sessionSlice.reducer,
    forgotPasswordState: forgotPasswordSlice.reducer
});

const appReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "authSlice/resetAuth") {
    storage.removeItem("persist:root");
    state = {} as RootState;
  }
  return rootReducer(state, action);
};
const persistedReducer = persistReducer({ key: "root", storage }, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
