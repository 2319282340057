import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector: React.FC = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        i18n.changeLanguage(event.target.value);
    };
    return (
        <div>
            <select
                onChange={handleLanguageChange}
                defaultValue={i18n.language}
                id="language-select"
            >
                <option value="en">English</option>
                <option value="ar">العربية</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
