import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useEdgesState, useNodesState } from 'reactflow';
import { DrawerComp } from '../../common/components/drawer/drawer.component';
import { IBranch } from '../../redux/features/workflows/interfaces/branch.interface';
import { IMessage } from '../../redux/features/workflows/interfaces/message.interface';
import {
    useAddConversationMutation,
    useGetConversationsQuery,
} from '../../redux/features/workflows/services/workflows.api';
import ConversationForm from './components/conversation.form';
import ConversationList from './components/conversations.component';
import { toEdge } from './extensions/branch.extension';
import { toNode } from './extensions/message.extension';
import { ImageMessageNode } from './nodes/image.message.node';
import { default as classes } from './workflow.page.module.css';
import FloatActionButton from '../../common/components/float.action.button';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';

type Direction = 'ltr' | 'rtl';

export const WorkflowList: React.FC<any> = ({}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [convoId, setConvoId] = useState<number>(0);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [branches, setBranches] = useState<IBranch[]>([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [newNodeIds, setNewNodeIds] = useState<number[]>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [newNode, setNewNode] = useState<IMessage | undefined>(undefined);
    const [newBranch, setNewBranch] = useState<IBranch | undefined>(undefined);

    const [showConvDrawer, setShowConvDrawer] = useState(false);

    const nodeTypes = useMemo(() => ({ imageNode: ImageMessageNode }), []);
    const { data: workflow, isLoading } = useGetConversationsQuery({
        id: convoId!,
    });
    const [addConvos, { isLoading: isAddingConv }] =
        useAddConversationMutation();

    useEffect(() => {
        if (workflow) {
            setNodes(
                workflow?.messages?.map((message, idx) =>
                    toNode(message, idx),
                ) || [],
            );
            setEdges(workflow?.branches?.map((branch) => toEdge(branch)) || []);
            setMessages(workflow?.messages || []);
            setBranches(workflow?.branches || []);
        }
    }, [workflow]);

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);

    useEffect(() => {
        if (newNode) {
            setNewNodeIds([...newNodeIds, newNode?.id]);
            setNodes([...nodes!, toNode(newNode, nodes.length)]);
            setMessages([...messages, newNode]);
        }
        if (newBranch) {
            setEdges([
                ...edges.map((edge) =>
                    parseInt(edge.id) == newBranch.id
                        ? toEdge(newBranch)
                        : edge,
                ),
            ]);
            setBranches([
                ...branches.map((br) =>
                    br.id === newBranch.id ? newBranch : br,
                ),
            ]);
        }
        setNewBranch(undefined);
        setNewNode(undefined);
    }, [newNode, newBranch]);

    return (
        <>
            <div>
                <ConversationList />
                {isLoading || isAddingConv ? (
                    <Spin size="large" className={classes.spinner} />
                ) : (
                    <>
                        <DrawerComp
                            title={t('convo.add_conversation')}
                            showing={showConvDrawer}
                            onClose={() => {
                                setShowConvDrawer(false);
                            }}
                        >
                            <ConversationForm
                                setShowConvDrawer={setShowConvDrawer}
                            />
                        </DrawerComp>
                    </>
                )}
            </div>
            <FloatActionButton
                icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                buttonAction={() => setShowConvDrawer(true)}
            />
        </>
    );
};
