// import { Table } from "antd";
import {
    UploadOutlined,
    FilterOutlined,
    MobileOutlined,
    WhatsAppOutlined,
    EditFilled,
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    DatePicker,
    Dropdown,
    Input,
    Menu,
    Modal,
    Pagination,
    Radio,
    Select,
    Space,
    Spin,
    Upload,
    Tooltip,
    Tag,
    Popover,
} from 'antd';
import { Option } from 'antd/lib/mentions';
import { ok } from 'assert';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import useAxios from '../../configs/axios.config';
import Recorder from '../../common/components/voicerecorder/recorder';
import classes from './table.module.css';
import { error } from 'console';
import showNotification from '../../common/components/notifications';
import { RequestDetails } from './request.details';
import { RequestDetailsV2 } from './request.details.v2';
import { CsvComponent } from './csv.export.component';
import FeedBackModal from './feedback.modal';
import {
    useMarkAsCompleteMutation,
    usePostFeedBackMutation,
    useQueriesQuery,
} from '../../redux/features/queries/services/queries.api';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { api } from '../../redux/api';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
export enum ReplyMessageType {
    Text,
    Attachment,
    Audio,
}

type Direction = 'ltr' | 'rtl';

const UserQueries = () => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [total, setTotal] = useState<number>(5);
    const [option, setOption] = useState(0);
    const [axiosInstance, responseInterceptor]: any = useAxios();
    const [contentModalVisible, setContentModalVisible] = useState(false);
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [index, setIndex] = useState<any>(null);
    const [dropVisible, setDropVisible] = useState(false);
    const [drop1Visible, setDrop1Visible] = useState(false);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [checkedIds, setCheckedIds] = useState<any>([]);
    const [messageType, setMessageType] = useState<number | null>(
        ReplyMessageType.Text,
    );
    const [text, setText] = useState<string | null>(null);
    const [queryId, setQueryId] = useState<number | null>(null);
    const [uploadAble, setUploadAble] = useState<any>(null);
    const [replying, setReplying] = useState<boolean>(false);
    const [audio, setAudio] = useState<any>(null);
    const [openPicker, setOpenPicker] = useState<any>({
        open1: false,
        open2: false,
    });
    const [isFbModalOpen, setIsFbModalOpen] = useState(false);
    const [elementId, setElementId] = useState<number | null>(null);
    const [feedBack, setFeedBack] = useState<string>('');
    const { RangePicker } = DatePicker;
    const dispatch = useAppDispatch();
    const filterMenu = useAppSelector((state) => state.authState.menu);
    const user = useAppSelector((state) => state.authState.user);

    const { data, isLoading, isError, refetch } = useQueriesQuery({
        page,
        limit: pageSize,
        option,
        startDate,
        endDate,
    });
    const [markAsCompleteRec, { isLoading: isCompleting }] =
        useMarkAsCompleteMutation();
    const [postFeedBack, { isLoading: isPostingFeedBack }] =
        usePostFeedBackMutation();

    const handleOk = async () => {
        await postFeedBack({
            id: elementId!,
            feedBack,
            page,
            limit: pageSize,
            option,
            startDate,
            endDate,
        });
        setFeedBack('');
        setIsFbModalOpen(false);
    };

    const handleCancelContentModal = () => {
        setContentModalVisible(false);
    };
    const handleCancelMessageModal = () => {
        setMessageModalVisible(false);
        setMessageType(ReplyMessageType.Text);
    };

    const onChecboxchange = (e: any, id: number) => {
        let ids = [...checkedIds];
        if (e.target.checked) {
            ids.push(id);
        }
        if (!e.target.checked) {
            ids = ids.filter((oldId) => oldId != id);
        }
        setCheckedIds(ids);
    };
    const sendMessage = async () => {
        setReplying(true);
        const body: any = { type: messageType };
        const formData = new FormData();

        if (messageType == ReplyMessageType.Text && text) {
            body.text = text;
        }
        if (messageType == ReplyMessageType.Attachment) {
            formData.append('file', uploadAble);
        }
        if (messageType == ReplyMessageType.Audio && audio) {
            formData.append('file', audio);
        }
        formData.append('item', JSON.stringify(body));
        try {
            const response = await axiosInstance.post(
                `responses/reply/${queryId}`,
                formData,
            );
            if (response.status == 201) {
                console.log('textresponse', response);
                showNotification({ desc: 'Message Sent!', type: 'success' });
            } else {
                throw new Error(response?.response?.data?.message);
            }
        } catch (err: any) {
            console.log('reply Error', err);
            showNotification({ desc: err?.message, type: 'error' });
        }
        setReplying(false);
        setMessageModalVisible(false);
        setMessageType(ReplyMessageType.Text);
    };

    const handleChange = (file: any) => {
        const reader = new FileReader();
        if (file) {
            const uploadAbleFile = file?.file?.originFileObj;
            if (uploadAbleFile) {
                setUploadAble(uploadAbleFile);
            }
        }
    };

    useEffect(() => {
        dispatch(api.util.invalidateTags(['Queries']));
    }, []);

    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <div style={{ direction: direction }}>
            <div style={{ paddingTop: "0.5rem", paddingRight: "0.5rem" }}>{data && <CsvComponent filterMenu={filterMenu} version={user.company.version} />}</div>
            <FeedBackModal
                isFbModalOpen={isFbModalOpen}
                setIsFbModalOpen={setIsFbModalOpen}
                setFeedBack={setFeedBack}
                feedBack={feedBack}
                handleOk={handleOk}
                isLoading={isPostingFeedBack}
            />
            <Modal
                title="Details"
                visible={contentModalVisible}
                onCancel={handleCancelContentModal}
                footer={null}
                className={classes['ant-modal-content']}
            >
                {/* {content()} */}
            </Modal>
            <Modal
                title="Details"
                visible={messageModalVisible}
                onCancel={handleCancelMessageModal}
                footer={null}
                className={classes['ant-modal-content']}
            >
                <div>
                    <Select
                        placeholder={'Message Type'}
                        defaultValue={messageType ? messageType : 'text'}
                        value={
                            messageType == ReplyMessageType.Attachment
                                ? 'Attachment'
                                : messageType == ReplyMessageType.Audio
                                  ? 'Audio'
                                  : 'Text'
                        }
                        style={{ width: 200, marginBottom: '10px' }}
                        onChange={(e: any) => setMessageType(parseInt(e))}
                    >
                        <Option value={'0'}>Text</Option>
                        <Option value={'1'}>Attachment</Option>
                        <Option value={'2'}>Audio</Option>
                    </Select>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {messageType == ReplyMessageType.Text ? (
                            <Input
                                placeholder="type messsage here ..."
                                onChange={(e: any) => setText(e.target.value)}
                            />
                        ) : messageType == ReplyMessageType.Attachment ? (
                            <Upload onChange={handleChange}>
                                <Button icon={<UploadOutlined />}>
                                    Click to Upload
                                </Button>
                            </Upload>
                        ) : (
                            messageType == ReplyMessageType.Audio && (
                                <>
                                    <Recorder
                                        setAudio={setAudio}
                                        audio={audio}
                                    />
                                </>
                            )
                        )}
                        <Button
                            type="primary"
                            style={{ marginTop: '10px' }}
                            onClick={() => sendMessage()}
                            loading={replying}
                            disabled={
                                !text && !uploadAble && !audio ? true : false
                            }
                        >
                            Send Message
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className={classes.SelectDiv}>
                <Select
                    defaultValue="Request"
                    style={{ width: '100%' }}
                    onChange={(e: any) => {
                        return setOption(e);
                    }}
                >
                    {filterMenu &&
                        Object.keys(filterMenu)?.map((key) => {
                            return (
                                <>
                                    <Option value={key}>
                                        {filterMenu[key].title}
                                    </Option>
                                </>
                            );
                        })}
                </Select>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '0.2rem',
                    }}
                >
                    <DatePicker
                        open={openPicker.open1}
                        renderExtraFooter={() => (
                            <Button
                                type="primary"
                                onClick={() =>
                                    setOpenPicker({
                                        ...openPicker,
                                        open1: false,
                                    })
                                }
                            >
                                cancel
                            </Button>
                        )}
                        placeholder="Start Date"
                        onChange={(e: any) => {
                            setStartDate(
                                moment(e._d).startOf('D').toISOString(),
                            );
                            setOpenPicker({ ...openPicker, open1: false });
                        }}
                        onClick={() =>
                            setOpenPicker({ ...openPicker, open1: true })
                        }
                    />
                    <DatePicker
                        open={openPicker.open2}
                        placeholder="End Date"
                        onChange={(e: any) => {
                            setEndDate(moment(e._d).endOf('D').toISOString());
                            setOpenPicker({ ...openPicker, open2: false });
                        }}
                        renderExtraFooter={() => (
                            <Button
                                type="primary"
                                onClick={() =>
                                    setOpenPicker({
                                        ...openPicker,
                                        open2: false,
                                    })
                                }
                            >
                                cancel
                            </Button>
                        )}
                        onClick={() =>
                            setOpenPicker({
                                ...openPicker,
                                open2: !openPicker.open2,
                            })
                        }
                    />
                </div>
            </div>
            {checkedIds.length > 0 && (
                <Button
                    className={classes.antBtn}
                    type="primary"
                    onClick={() =>
                        markAsCompleteRec({
                            queryIds: checkedIds,
                            page,
                            limit: pageSize,
                            option,
                            startDate,
                            endDate,
                            setCheckedIds,
                        })
                    }
                >
                    Mark As Complete
                </Button>
            )}
            <Table className={classes.Table} style={{ margin: '30px 0 0' }}>
                <Thead>
                    <Tr>
                        <Th>{t('_deep.mark')}</Th>
                        <Th>#</Th>
                        <Th>
                            {t(`_deep.date`)}{' '}
                            <Dropdown
                                className={classes.Dropdown}
                                visible={drop1Visible}
                                overlay={() => (
                                    <>
                                        <Menu multiple={false}>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    return (
                                                        console.log(e),
                                                        setDrop1Visible(false)
                                                    );
                                                }}
                                                defaultValue={option}
                                            >
                                                <Space
                                                    direction="vertical"
                                                    style={{ padding: '10px' }}
                                                >
                                                    <RangePicker
                                                        placeholder={[
                                                            t('start_date'),
                                                            t('end_date'),
                                                        ]}
                                                        onChange={(e: any) => {
                                                            setStartDate(
                                                                moment(e[0]._d)
                                                                    .startOf(
                                                                        'D',
                                                                    )
                                                                    .toISOString(),
                                                            );
                                                            setEndDate(
                                                                moment(e[1]._d)
                                                                    .endOf('D')
                                                                    .toISOString(),
                                                            );
                                                            setDrop1Visible(
                                                                false,
                                                            );
                                                        }}
                                                    />
                                                    <div>
                                                        <Button
                                                            style={{
                                                                marginLeft:
                                                                    '5px',
                                                            }}
                                                            onClick={() => {
                                                                return (
                                                                    setEndDate(
                                                                        null,
                                                                    ),
                                                                    setDrop1Visible(
                                                                        false,
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            {t('reset')}
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                marginLeft:
                                                                    '5px',
                                                            }}
                                                            onClick={() => {
                                                                return setDrop1Visible(
                                                                    false,
                                                                );
                                                            }}
                                                        >
                                                            {t('cancel')}
                                                        </Button>
                                                    </div>
                                                </Space>
                                            </Radio.Group>
                                        </Menu>
                                    </>
                                )}
                            >
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDrop1Visible(true);
                                    }}
                                >
                                    <Space>
                                        <FilterOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </Th>
                        <Th>{t('_deep.time')}</Th>
                        <Th>{t('_deep.applicant_name')}</Th>
                        <Th>
                            {t('_deep.request')}{' '}
                            <Dropdown
                                className={classes.Dropdown}
                                visible={dropVisible}
                                overlay={() => (
                                    <>
                                        <Menu multiple={false}>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    return (
                                                        setOption(
                                                            e.target.value,
                                                        ),
                                                        setDropVisible(false)
                                                    );
                                                }}
                                                defaultValue={option}
                                            >
                                                <Space direction="vertical">
                                                    {filterMenu &&
                                                        Object.keys(
                                                            filterMenu,
                                                        )?.map((key) => {
                                                            return (
                                                                <>
                                                                    <Radio
                                                                        value={parseInt(
                                                                            key,
                                                                        )}
                                                                    >
                                                                        {
                                                                            filterMenu[
                                                                                key
                                                                            ]
                                                                                .title
                                                                        }
                                                                    </Radio>
                                                                </>
                                                            );
                                                        })}

                                                    <div>
                                                        <Button
                                                            style={{
                                                                marginLeft:
                                                                    '5px',
                                                            }}
                                                            onClick={() => {
                                                                return (
                                                                    setOption(
                                                                        0,
                                                                    ),
                                                                    setDropVisible(
                                                                        false,
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            {t('reset')}
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                            onClick={() => {
                                                                setDropVisible(
                                                                    false,
                                                                );
                                                            }}
                                                        >
                                                            {t('cancel')}
                                                        </Button>
                                                    </div>
                                                </Space>
                                            </Radio.Group>
                                        </Menu>
                                    </>
                                )}
                            >
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDropVisible(true);
                                    }}
                                >
                                    <Space>
                                        <FilterOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </Th>

                        {/* <Th>Description</Th> */}
                        {/* <Th>Address</Th> */}
                        <Th>{t('_deep.mobile')}</Th>
                        <Th>{t('_deep.status')}</Th>
                        <Th>{t('_deep.progress')}</Th>
                        <Th>{t('_deep.feedback')}</Th>
                        {/* <Th>Response</Th> */}
                    </Tr>
                </Thead>
                {(isLoading || isCompleting) && (
                    <Spin
                        size="large"
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            top: '50%',
                            left: '45%',
                        }}
                    />
                )}

                {data?.data?.length == 0 && !(isLoading || isCompleting) && (
                    <div className={classes.notFound}>
                        <h2>No Data Yet</h2>
                    </div>
                )}

                <Tbody>
                    {data?.data?.map((item: any, i: number) => {
                        return (
                            <Tr
                                key={item.id}
                                // style={{ backgroundColor: item.status == 0 && "#D7DBDF" }}
                            >
                                <Td>
                                    <Checkbox
                                        checked={
                                            item.state == 3 ||
                                            checkedIds.includes(item.id)
                                        }
                                        disabled={
                                            item.state == 3 || !item?.feedback
                                        }
                                        onChange={(e: any) => {
                                            onChecboxchange(e, item.id);
                                        }}
                                    ></Checkbox>
                                </Td>
                                <Td
                                    style={{ padding: '5px' }}
                                    // onClick={() => getDetails(item.id, i, item.status)}
                                >
                                    {data?.meta?.totalItems! -
                                        ((page - 1) * pageSize + (i + 1)) +
                                        1}
                                </Td>
                                <Td
                                    style={{ padding: '5px' }}
                                    // onClick={() => getDetails(item.id, i, item.status)}
                                >
                                    {moment(item.createdAt).format(
                                        'DD/MM/YYYY',
                                    )}
                                </Td>
                                {/* <Tooltip placement="topLeft" title={item.from.name}> */}
                                <Td>
                                    {moment(item.createdAt).format('HH:mm:ss')}
                                </Td>
                                {item.from ? (
                                    <Td>
                                        {item.from?.name &&
                                        item?.from?.name?.length < 30
                                            ? item.from?.name
                                            : item.from?.name.slice(0, 30) +
                                              '...'}
                                    </Td>
                                ) : (
                                    <Td>-</Td>
                                )}

                                {/* </Tooltip> */}
                                <Td
                                    // onClick={() => getDetails(item.id, i, item.status)}
                                    align="left"
                                >
                                    {user.company.version === 1 && (
                                        <RequestDetails
                                            request={item?.options}
                                            filterMenu={filterMenu}
                                        />
                                    )}
                                    {user.company.version === 2 && (
                                        <RequestDetailsV2
                                            request={item?.options}
                                            filterMenu={filterMenu}
                                        />
                                    )}
                                </Td>
                                {/* <Tooltip placement="topLeft" title={item.freeText}>
                  <Td onClick={() => getDetails(item.id, i, item.status)}>
                    {item.freeText &&
                      (item.freeText.length < 30
                        ? item.freeText
                        : item.freeText.slice(0, 30) + "...")}
                  </Td>
                </Tooltip> */}
                                {/* <Tooltip placement="topLeft" title={item.from.address}>
                  <Td onClick={() => getDetails(item.id, i, item.status)}>
                    {item.from.address && item.from.address.length < 30
                      ? item.from.address
                      : item?.from?.address?.slice(0, 30) + "..."}
                  </Td>
                </Tooltip> */}
                                {item.from ? (
                                    <Td style={{ padding: '5px' }}>
                                        <div className={classes.mobContainer}>
                                            <p>{item.from?.mobile}</p>
                                            <a
                                                href={
                                                    'whatsapp://send?phone=' +
                                                    (item.from?.mobile.substring(
                                                        0,
                                                        3,
                                                    ) != '+92'
                                                        ? '+92' +
                                                          item.from?.mobile
                                                        : item.from?.mobile)
                                                }
                                                className={classes.whatsApp}
                                            >
                                                <WhatsAppOutlined />
                                            </a>
                                            <a
                                                href={
                                                    'tel:' + item.from?.mobile
                                                }
                                                className={classes.mobile}
                                            >
                                                <MobileOutlined />
                                            </a>
                                        </div>
                                    </Td>
                                ) : (
                                    <Td>-</Td>
                                )}

                                <Td
                                // onClick={() => getDetails(item.id, i, item.status)}
                                >
                                    <img
                                        src={
                                            item.state !== 3
                                                ? 'https://cdn-icons-png.flaticon.com/512/88/88051.png'
                                                : 'https://cdn-icons-png.flaticon.com/512/6164/6164403.png'
                                        }
                                        width="20px"
                                        height="20px"
                                    />
                                </Td>
                                <Td
                                // onClick={() => getDetails(item.id, i, item.status)}
                                >
                                    {item.state == 1
                                        ? 'New'
                                        : item.state == 2
                                          ? 'Inprogress...'
                                          : 'Completed'}
                                </Td>
                                <Td
                                // onClick={() => getDetails(item.id, i, item.status)}
                                >
                                    {item?.feedback ? (
                                        item?.feedback
                                    ) : (
                                        <p>
                                            <EditFilled
                                                onClick={() => {
                                                    setIsFbModalOpen(true);
                                                    setElementId(item?.id);
                                                }}
                                            />
                                        </p>
                                    )}
                                </Td>
                                {/* <Td>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setMessageModalVisible(true);
                      setQueryId(item.id);
                    }}
                  >
                    Reply
                  </Button>
                </Td> */}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            {data?.meta.totalItems! > pageSize && (
                <Pagination
                    defaultCurrent={1}
                    pageSize={pageSize}
                    total={data?.meta.totalItems}
                    pageSizeOptions={[10, 15, 20]}
                    style={{ float: 'right', marginTop: '0.5rem' }}
                    showLessItems
                    onChange={(p: number, b: any) => {
                        setPageSize(b);
                        setPage(p);
                    }}
                />
            )}
        </div>
    );
};

export default UserQueries;
