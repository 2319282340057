import { IMessage } from '../../../redux/features/sessions/interface/session.messages.interface';

type Props = {
    message: IMessage
}

export const ChatDocumentComponent: React.FC<Props> = ({ message }) => {
    const payload = JSON.parse(message.message);
    return (
        <div>
            <div style={{ paddingTop: "10%", textAlign: "center", width: "100%", height: "100px", backgroundColor: "#ccc", borderColor: "blue", borderRadius: 4 }}>
                <div><b>Document</b></div>
                <div>{payload.filename}</div>
            </div>
            <p>{payload.caption}</p>
        </div>
    );
}