import React from "react";
import { Divider } from "antd";


export const CustomDivider = () => {
    return (
        <Divider
            style={{
                borderColor: 'lightgrey',
                borderWidth: '1px',
                margin: '15px 0 15px 0',
            }}
        />
    );
};