import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n/i18n';

interface Props {
    location: any;
    type: string;
}

type Direction = 'ltr' | 'rtl';

const LocationOptionComponent: React.FC<Props> = ({ type, location }) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <>
            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_location.caption')}
                name={`${type}Cap`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t(
                            'form.node_location.caption_placeholder',
                        )}
                    />
                }
            </Form.Item>
            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_location.address')}
                name={`${type}Address`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t(
                            'form.node_location.address_placeholder',
                        )}
                    />
                }
            </Form.Item>
            <Row gutter={4}>
                <Col span={5}>
                    <div>{t('form.node_location.coordinates')}</div>
                </Col>
                <Col span={9}>
                    <Form.Item
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: '5px', direction: direction }}
                        // labelCol={{ span: 8}}
                        // labelAlign="left"
                        // label={t('form.node_location.latitude')}
                        name={`${type}Lat`}
                        rules={[{}]}
                    >
                        {
                            <Input
                                type={'text'}
                                placeholder={t(
                                    'form.node_location.latitude_placeholder',
                                )}
                            />
                        }
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: '5px', direction: direction }}
                        name={`${type}Lon`}
                        rules={[{}]}
                    >
                        {
                            <Input
                                type={'text'}
                                placeholder={t(
                                    'form.node_location.longitude_placeholder',
                                )}
                            />
                        }
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default LocationOptionComponent;
