import {IBranch} from "../../../redux/features/workflows/interfaces/branch.interface";
import {Edge, MarkerType} from "reactflow";

export const toEdge = (branch: IBranch): Edge => {
    return {
        id: branch.id.toString(),
        source: branch.from.id.toString(),
        target: branch.to.id.toString(),
        data: branch,
        label: branch.isBackLink ? "" : branch.condition,
        style: branch.isBackLink ? { strokeOpacity: .2, strokeDasharray: 10 } : { strokeWidth: 2 },
        markerEnd: {
            type: MarkerType.ArrowClosed
        }
    }
}
