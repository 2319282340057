import React, { useEffect, useState, useRef } from 'react';

import 'react-chat-elements/dist/main.css';
import { MessageBox, Input, MessageType } from 'react-chat-elements';
import { Spin } from 'antd';
import { useGetMessagesQuery } from '../../redux/features/sessions/services/sessions.api';
import { IMessage } from '../../redux/features/sessions/interface/session.messages.interface';
import { Token } from '@mui/icons-material';
import { ChatTextComponent } from './components/chat.text.component';
import { ChatImageComponent } from './components/chat.image.component';
import { ChatInteractiveListComponent } from './components/chat.interactive.list.component';
import { ChatDocumentComponent } from './components/chat.document.component';

type Props = {
    sessionId: any;
};

const ChatDrawer: React.FC<Props> = ({ sessionId }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const {
        data: mes,
        error,
        isLoading,
    } = useGetMessagesQuery({
        id: sessionId,
    });
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [mes]);

    const getMessageType = (
        message: IMessage,
    ):
        | 'audio'
        | 'video'
        | 'text'
        | 'location'
        | 'photo'
        | 'spotify'
        | 'meetingLink'
        | 'file'
        | 'system'
        | 'meeting' => {
        if (message.messageType === 'text') {
            return 'text';
        } else if (message.messageType === 'image') {
            return 'text';
        } else if (message.messageType === 'location') {
            return 'location';
        } else {
            return 'text';
        }
    };

    const renderMessage = (message: IMessage) => {
        switch (message.messageType) {
            case 'text':
                return <ChatTextComponent message={message} />;
            case 'document':
                return <ChatDocumentComponent message={message} />;
            case 'image':
                return <ChatImageComponent message={message} />;
            case 'interactive.list':
                return <ChatInteractiveListComponent message={message} />;
        }
    };

    const getData = (message: IMessage): any => {
        if (message.messageType === 'location') {
            const payload = JSON.parse(message.message);
            const token =
                'pk.eyJ1IjoiYWZ0YWJhaWciLCJhIjoiY2xvMTV1ZGY2MWphODJtb2FlNjJ6dHcyciJ9.WtyOmNML3mZmerfCkQIhkw';
            return {
                latitude: payload.latitude,
                longitude: payload.longitude,
                staticURL: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-circle+FF0000(${payload.longitude},${payload.latitude})/${payload.longitude},${payload.latitude},12/300x200@2x?access_token=${token}`,
            };
        } else {
            return {};
        }
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            {isLoading && (
                <Spin
                    size="large"
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '50%',
                        left: '45%',
                    }}
                />
            )}
            <div
                style={{ overflow: 'auto', paddingBottom: 20, height: '100%' }}
                ref={containerRef}
            >
                {mes &&
                mes.data !== undefined &&
                mes.data.messages.length > 0 ? (
                    mes.data.messages.map((message) => (
                        <MessageBox
                            type={getMessageType(message)}
                            notch={false}
                            status="sent"
                            focus={false}
                            id={message.id}
                            key={message.id}
                            retracted={false}
                            date={new Date(message.createdAt)}
                            forwarded={false}
                            replyButton={false}
                            // @ts-ignore
                            text={renderMessage(message)}
                            removeButton={false}
                            title={
                                message.type === 0
                                    ? mes && mes.data.username
                                    : message.type === 1
                                      ? `Agent`
                                      : `Bot`
                            }
                            data={getData(message)}
                            position={message.type === 0 ? 'left' : 'right'}
                            titleColor={message.type === 0 ? 'red' : 'green'}
                            styles={{
                                backgroundColor:
                                    message.type === 0
                                        ? 'lightblue'
                                        : '#F0F0F0',
                                width: '70%',
                                marginBottom: '10px',
                            }}
                        />
                    ))
                ) : (
                    <>
                        {!isLoading && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                No Messages Found
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ChatDrawer;
