export const filterData = (request: string[], filterMenu: any) => {
  let obj: any = [];
  let data: any = [];
  
  request?.forEach((item, i) => {
    if (i == 0) {
      obj = filterMenu[item];
      data.push(obj?.title);
      return;
    }
    obj = obj !== undefined && obj[item];
    data.push(obj?.title);
  });
  return data;
};
