import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Spin, Switch, message } from 'antd';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
    Connection,
    Controls,
    Node,
    ReactFlow,
    useEdgesState,
    useNodesState,
    Edge,
    isNode,
    XYPosition,
    NodeChange,
    EdgeChange,
    NodeRemoveChange,
    EdgeRemoveChange,
} from 'reactflow';

import { DrawerComp } from '../../common/components/drawer/drawer.component';
import { IBranch } from '../../redux/features/workflows/interfaces/branch.interface';
import { IMessage } from '../../redux/features/workflows/interfaces/message.interface';
import {
    useActivateWorkflowMutation,
    useAddConversationMutation,
    useGetConversationsQuery,
    useGetWorkflowActionsQuery,
} from '../../redux/features/workflows/services/workflows.api';
import UpsertBranchComponent from './components/upsert.branch.component';
import UpsertMessageComponent from './components/upsert.message.component';
import { toEdge } from './extensions/branch.extension';
import { toNode, toNodePosition } from './extensions/message.extension';
import { ImageMessageNode } from './nodes/image.message.node';
import { default as classes } from './workflow.page.module.css';
import { Layout } from 'antd';
import { Typography } from 'antd';
import { workflowSlice } from './slice/workflow.slice';
import { useDispatch, useSelector } from 'react-redux';
import { PositionAxis } from './slice/workflow.slice';
import { rootReducer, useAppSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from './components/context.menu';
import gridBlack from '../../assets/grid_black.png';
const { Header } = Layout;
const { Text } = Typography;

type MenuState = {
    id: string;
    top: number | false;
    left: number | false;
    right: number | false;
    bottom: number | false;
} | null;
export const WorkflowDetailPage: React.FC<any> = ({}) => {
    const { t } = useTranslation();
    const [convoId, setConvoId] = useState<number>(0);
    const [duplicatedMessage, setDuplicateMessage] = useState<IMessage>();
    const ref = useRef<HTMLDivElement>(null);
    const selectedNodeRef = useRef<Node | null>(null);
    const [menu, setMenu] = useState<MenuState>(null);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [branches, setBranches] = useState<IBranch[]>([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [newNodeIds, setNewNodeIds] = useState<number[]>([]);
    const [updatedNodeIds, setUpdatedNodeIds] = useState<number[]>([]);
    const [newBranchIds, setNewBranchIds] = useState<number[]>([]);
    const [updatedBranchIds, setUpdatedBranchIds] = useState<number[]>([]);
    const [updatedBranch, setUpdatedBranch] = useState<number | null>(null);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [newNode, setNewNode] = useState<IMessage | undefined>(undefined);
    const [updatedNode, setUpdatedNode] = useState<IMessage | undefined>(
        undefined,
    );
    const [isNodeChange, setIsNodeChange] = useState(false);
    // const highestIdRef = useRef(1);
    const dispatch = useDispatch();
    const [newBranch, setNewBranch] = useState<IBranch | undefined>(undefined);
    const nodePositions = useAppSelector(
        (state) => state.workflowState.positions,
    );
    const [showingMessageDrawer, setShowingMessageDrawer] = useState(false);
    const [showingBranchDrawer, setShowingBranchDrawer] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<
        IMessage | undefined
    >(undefined);

    let { id } = useParams();

    const [selectedBranch, setSelectedBranch] = useState<IBranch | undefined>(
        undefined,
    );

    const [disableActiveButton, setDisableActiveButton] =
        useState<boolean>(false);

    const [deletedNodes, setDeletedNodes] = useState<number[]>([]);
    const [deletedEdges, setDelegateEdges] = useState<number[]>([]);

    const clearStates = () => {
        setEdges([]);
        setNodes([]);
        setBranches([]);
        setMessages([]);
        setConvoId(0);
        setNewNodeIds([]);
        setNewBranchIds([]);
        setUpdatedBranchIds([]);
        setNewNode(undefined);
        setNewBranch(undefined);
        setShowingMessageDrawer(false);
        setShowingBranchDrawer(false);
        setSelectedMessage(undefined);
        setSelectedBranch(undefined);
        setDisableActiveButton(false);
        setUpdatedNodeIds([]);
        if (workflow) {
            setNodes(
                (workflow?.messages || [])
                    .filter((message) => message.key !== 'invalid.option')
                    .map((message) => {
                        return toNodePosition(
                            message,
                            workflow?.coordinates?.find(
                                (position: any) =>
                                    parseInt(position.id) === message.id,
                            )?.position!,
                        );
                    }) || [],
            );
            setEdges(workflow?.branches?.map((branch) => toEdge(branch)) || []);
            setMessages(workflow?.messages || []);
            setBranches(workflow?.branches || []);
        }
    };
    const nodeTypes = useMemo(() => ({ imageNode: ImageMessageNode }), []);
    const {
        data: workflow,
        isLoading,
        refetch,
    } = useGetConversationsQuery({
        id: Number(id)!,
    });
    const { data: actions, isLoading: isLoadingActions } =
        useGetWorkflowActionsQuery();
    const [
        addConvos,
        { isLoading: isAddingConv, isSuccess: successfullySave },
    ] = useAddConversationMutation();

    useEffect(() => {
        dispatch(workflowSlice.actions.retrieveNodePositions(workflow?.id));
    }, [successfullySave, workflow]);

    useEffect(() => {
        if (workflow) {
            setNodes(
                (workflow?.messages || [])
                    .filter((message) => message.key !== 'invalid.option')
                    .map((message, idx) => {
                        return toNodePosition(
                            message,
                            workflow?.coordinates?.find(
                                (position: any) =>
                                    parseInt(position.id) === message.id,
                            )?.position!,
                        );
                    }) || [],
            );
            setEdges(workflow?.branches?.map((branch) => toEdge(branch)) || []);
            setMessages(workflow?.messages || []);
            setBranches(workflow?.branches || []);
        }
    }, [workflow]);

    useEffect(() => {
        if (selectedMessage) {
            setShowingMessageDrawer(true);
        }
        if (selectedBranch) {
            setShowingBranchDrawer(true);
        }
    }, [selectedMessage, selectedBranch]);

    useEffect(() => {
        if (updatedBranch) {
            setUpdatedBranchIds([...updatedBranchIds, updatedBranch]);
            setUpdatedBranch(null);
        }
    }, [updatedBranch]);

    const findMessage = (id: number): IMessage | undefined => {
        return messages.find((message) => message.id === id);
    };
    const findNode = (id: number): Node | undefined => {
        return nodes.find((node) => parseInt(node.id) === id);
    };
    const findBranch = (fromId: number, toId: number): IBranch | undefined => {
        return branches.find(
            (branch) => branch.from.id === fromId && branch.to.id === toId,
        );
    };

    const showMessage = (node: Node) => {
        const message = findMessage(parseInt(node.id));
        setSelectedMessage(message);
    };

    const showBranch = (edge: Edge) => {
        const branch = findBranch(edge.data.from.id, edge.data.to.id);
        setSelectedBranch(branch);
    };

    const onConnect = (connection: Connection) => {
        const from = messages.find(
            (message) => connection.source === message.id.toString(),
        );
        const to = messages.find(
            (message) => connection.target === message.id.toString(),
        );
        if (!from || !to) {
            console.error('Cannot find source or target node for connection');
            return;
        }
        const id = new Date().getTime();
        setNewBranchIds([...newBranchIds, id]);
        const newBranch: IBranch = {
            id: id,
            from: from!,
            to: to!,
            isAnonymous: false,
            fromIndex: true,
            toIndex: true,
            isBackLink: false,
        };

        if (
            !branches.find(
                (branch) =>
                    branch.from.id === newBranch.from.id &&
                    branch.to.id === newBranch.to.id,
            )
        ) {
            setBranches([...branches, newBranch]);
            setEdges([...edges, toEdge(newBranch)]);
        }
    };
    const nodesChanged = (changes: NodeChange[]) => {
        setDeletedNodes([
            ...deletedNodes,
            ...changes
                .filter((change) => change.type === 'remove')
                .map((change) => parseInt((change as NodeRemoveChange).id)),
        ]);
        onNodesChange(changes);
    };

    const edgesChanged = (changes: EdgeChange[]) => {
        setDelegateEdges([
            ...deletedEdges,
            ...changes
                .filter((change) => change.type === 'remove')
                .map((change) => parseInt((change as EdgeRemoveChange).id)),
        ]);
        onEdgesChange(changes);
    };

    useEffect(() => {
        if (newNode) {
            setNewNodeIds([...newNodeIds, newNode?.id]);
            setNodes([...nodes!, toNode(newNode, nodes.length)]);
            setMessages([...messages, newNode]);
            setNewNode(undefined);
        }
        if (duplicatedMessage) {
            setNewNodeIds([...newNodeIds, duplicatedMessage.id]);
            setNodes([...nodes, toNode(duplicatedMessage, nodes.length)]);
            setMessages([...messages, duplicatedMessage]);
            setDuplicateMessage(undefined);
        }
        if (newBranch) {
            setEdges([
                ...edges.map((edge) =>
                    edge.data.from.id == newBranch.from.id &&
                    edge.data.to.id == newBranch.to.id
                        ? toEdge(newBranch)
                        : edge,
                ),
            ]);
            setBranches([
                ...branches.map((br) =>
                    br.from.id === newBranch.from.id &&
                    br.to.id === newBranch.to.id
                        ? newBranch
                        : br,
                ),
            ]);
            setNewBranch(undefined);
            setSelectedBranch(undefined);
        }
    }, [newNode, newBranch, duplicatedMessage]);

    useEffect(() => {
        if (updatedNode) {
            setUpdatedNodeIds([...updatedNodeIds, updatedNode?.id]);
            const node = findNode(updatedNode.id);
            node &&
                setNodes([
                    ...nodes!,
                    toNodePosition(updatedNode, node!.position),
                ]);
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message.id === updatedNode.id ? updatedNode : message,
                ),
            );
            setUpdatedNode(undefined);
        }
    }, [updatedNode]);

    const addC = async () => {
        const coordinates = nodes
            .filter((item) => item.id && item.position)
            .map((item) => ({ id: item.id, position: item.position }));
        console.log('coordinates:', coordinates);
        await addConvos({
            convoId: +id!,
            messages,
            branches,
            newNodeIds,
            newBranchIds,
            nodeCoordinates: coordinates,
            deletedMessageIds: deletedNodes,
            deletedBranchIds: deletedEdges,
        });
        clearStates();
        setIsNodeChange(false);
    };

    useEffect(() => {
        refetch();
    }, [workflow, disableActiveButton]);

    const isDirty = () => {
        return (
            newNodeIds.length > 0 ||
            updatedNodeIds.length > 0 ||
            newBranchIds.length > 0 ||
            updatedBranchIds.length > 0 ||
            isNodeChange
        );
    };

    const onNodeContextMenu = useCallback(
        (event: React.MouseEvent, node: Node) => {
            event.preventDefault();
            if (ref.current) {
                const pane = ref.current.getBoundingClientRect();
                selectedNodeRef.current = node;

                setMenu({
                    id: node.id,
                    top:
                        event.clientY < pane.height - 200
                            ? event.clientY
                            : false,
                    left:
                        event.clientX < pane.width - 200
                            ? event.clientX
                            : false,
                    right:
                        event.clientX >= pane.width - 200
                            ? pane.width - event.clientX
                            : false,
                    bottom:
                        event.clientY >= pane.height - 200
                            ? pane.height - event.clientY
                            : false,
                });
            }
        },
        [setMenu],
    );
    const dirty = isDirty();
    const onDuplicateNode = () => {
        if (selectedNodeRef.current) {
            const node = selectedNodeRef.current;
            const duplicatedNewNodeId = messages?.length;
            const duplicatedNode = {
                ...node,
                id: duplicatedNewNodeId,
                data: {
                    ...node.data,
                    message: {
                        ...node.data.message,
                        id: duplicatedNewNodeId,
                        key: `d_${node.data.message.key}`,
                        updatedAt: null,
                        createdAt: new Date(Date.now()).toISOString(),
                    },
                },
            };
            setDuplicateMessage(duplicatedNode.data.message);
            setMenu(null);
        }
    };

    const onPaneClick = useCallback(() => setMenu(null), [setMenu]);
    return (
        <>
            {dirty && (
                <Header className={classes.subHeader}>
                    <Text
                        style={{
                            color: 'white',
                        }}
                        strong={true}
                    >
                        {`${t('unsaved_changes')}`}
                    </Text>
                    <Button
                        type="primary"
                        style={{
                            float: 'right',
                            marginTop: '0.8rem',
                            borderRadius: '0.3rem',
                            borderBlockColor: 'ActiveBorder',
                            borderInlineColor: 'ActiveBorder',
                        }}
                        onClick={() => {
                            if (
                                (newNodeIds.length > 0 || branches || messages,
                                newBranchIds)
                            ) {
                                addC();
                            }
                        }}
                    >
                        <SaveOutlined />
                        {`${t('save')}`}
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            float: 'right',
                            margin: '0.8rem',
                            borderRadius: '0.3rem',
                            backgroundColor: '#202123',
                            borderBlockColor: 'white',
                            borderInlineColor: 'white',
                        }}
                        onClick={() => {
                            setIsNodeChange(false);
                            clearStates();
                            refetch();
                        }}
                    >
                        <DeleteOutlined />
                        {`${t('discard')}`}
                    </Button>
                </Header>
            )}

            <div className={classes.container}>
                {isLoading || isAddingConv ? (
                    <Spin size="large" className={classes.spinner} />
                ) : (
                    <>
                        <DrawerComp
                            title={
                                selectedMessage
                                    ? t('update_node')
                                    : t('add_new_node')
                            }
                            showing={showingMessageDrawer}
                            onClose={() => {
                                setShowingMessageDrawer(false);
                                setSelectedMessage(undefined);
                                setNewNode(undefined);
                            }}
                        >
                            <UpsertMessageComponent
                                message={selectedMessage}
                                showingMessageDrawer={showingMessageDrawer}
                                setMessage={setSelectedMessage}
                                setNewNode={setNewNode}
                                setUpdatedNode={setUpdatedNode}
                                length={messages?.length}
                                setShowingMessageDrawer={
                                    setShowingMessageDrawer
                                }
                                actions={actions!}
                            />
                        </DrawerComp>
                        <DrawerComp
                            title={
                                selectedBranch
                                    ? `${t('form.branch.branch')} (${selectedBranch.id})`
                                    : `${t('form.branch.branch')}`
                            }
                            showing={showingBranchDrawer}
                            onClose={() => {
                                setShowingBranchDrawer(false);
                                setSelectedBranch(undefined);
                                setNewBranch(undefined);
                            }}
                        >
                            <UpsertBranchComponent
                                branch={selectedBranch}
                                setBranch={setSelectedBranch}
                                setNewBranch={setNewBranch}
                                setUpdatedBranch={setUpdatedBranch}
                                length={branches?.length}
                                setShowingBranchDrawer={setShowingBranchDrawer}
                            />
                        </DrawerComp>
                        <div
                            style={{
                                backgroundImage: `url(${gridBlack})`,
                                backgroundSize: '110% 110%',
                                backgroundPosition: 'center',
                                borderRadius: 0,
                                width: '100%',
                                height: '80vh',
                                overflow: 'hidden',
                            }}
                        >
                            <Button
                                type="primary"
                                style={{
                                    float: 'right',
                                    margin: '0.5rem',
                                    backgroundColor: '145286',
                                    color: 'white',
                                    borderRadius: '0.3rem',
                                }}
                                onClick={() => setShowingMessageDrawer(true)}
                            >
                                <PlusOutlined />
                            </Button>

                            <ReactFlow
                                ref={ref}
                                minZoom={0.1}
                                snapToGrid
                                snapGrid={[4, 4]}
                                nodes={nodes}
                                edges={edges}
                                nodeTypes={nodeTypes}
                                onNodeDragStop={(event, node) =>
                                    setIsNodeChange(true)
                                }
                                onNodesChange={nodesChanged}
                                onEdgesChange={edgesChanged}
                                onNodeClick={(event, node) => {
                                    showMessage(node);
                                }}
                                onEdgeClick={(e, branch) => {
                                    console.log(e);
                                    showBranch(branch);
                                }}
                                onConnect={onConnect}
                                onNodeContextMenu={onNodeContextMenu}
                                fitView
                            >
                                <Controls style={{ marginBottom: '5rem' }} />
                            </ReactFlow>
                            {menu && (
                                <ContextMenu
                                    onClick={onPaneClick}
                                    {...menu}
                                    onDuplicate={onDuplicateNode}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
