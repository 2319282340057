import { api } from '../../../api';

export const apiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsersStats: builder.query<any, void>({
            query: () => {
                return {
                    url: 'users/userstats',
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useGetUsersStatsQuery, useLazyGetUsersStatsQuery } = apiSlice;
