import React, { useEffect, useState } from 'react';
import { Card, Space, Spin } from 'antd';
import classes from '../dashboard.module.css';
import { QuestionCircleOutlined, UserSwitchOutlined } from '@ant-design/icons';
import i18n from '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

interface props {
    title: string;
    entity: 'Users' | 'Queries';
    count: number;
    newQ?: number;
    completedQ?: number;
    usersLoading?: boolean;
    queryLoading?: boolean;
    isQuery?: boolean;
}

type Direction = 'ltr' | 'rtl';

const StatCard: React.FC<props> = ({
    title,
    entity,
    count,
    newQ,
    completedQ,
    usersLoading,
    queryLoading,
    isQuery,
}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <>
            <Space direction="horizontal" style={{ direction: direction }}>
                <Card
                    className={classes['card']}
                    style={{
                        backgroundColor: '#f9f9f9',
                    }}
                    title={
                        <span className={classes['card-title']}>{title}</span>
                    }
                >
                    <div style={{ display: 'flex' }}>
                        {usersLoading || queryLoading ? (
                            <Spin size="large" />
                        ) : (
                            <>
                                {isQuery ? (
                                    <>
                                        <div
                                            className={classes['card-content']}
                                        >
                                            <QuestionCircleOutlined
                                                className={classes['icon']}
                                            />
                                            <p className={classes['card-text']}>
                                                {t('dashboard.new')} {entity}
                                            </p>
                                            <p
                                                className={
                                                    classes['card-number']
                                                }
                                            >
                                                {newQ ?? 0}
                                            </p>
                                        </div>
                                        <div
                                            className={classes['card-content']}
                                        >
                                            <QuestionCircleOutlined
                                                className={classes['icon']}
                                            />
                                            <p
                                                className={classes['card-text']}
                                                style={{ color: 'green' }}
                                            >
                                                {t('dashboard.completed')}{' '}
                                                {entity}
                                            </p>
                                            <p
                                                className={
                                                    classes['card-number']
                                                }
                                            >
                                                {completedQ ?? 0}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={classes['card-content']}
                                        >
                                            <UserSwitchOutlined
                                                className={classes['icon']}
                                            />
                                            <p className={classes['card-text']}>
                                                {t('dashboard.total')} {entity}
                                            </p>
                                            <p
                                                className={
                                                    classes['card-number']
                                                }
                                            >
                                                {count}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </>
                        )}{' '}
                    </div>
                </Card>
            </Space>
        </>
    );
};

export default StatCard;
