import { Node, XYPosition } from "reactflow";

export const toNode = (message: any, idx?: number): Node => {
    return {
        id: message.id.toString(),
        type: "imageNode",
        position: { x: idx ? idx + idx * 110 : 0, y: 15 },
        data: {
            label: message.title,
            message: message
        },
    };
};
export const toNodePosition = (message: any,  position: XYPosition): Node => {
    return {
        id: message.id.toString(),
        type: "imageNode",
        position: { x: position ? position.x  : 0, y: position ? position.y : 15 },
        data: {
            label: message.title,
            message: message
        },
    };
};
