import { api } from '../../../api';
import { Template } from '../interfaces/template.inteface';

export const apiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getTemplates: builder.query<Template[], void>({
            query: () => {
                return {
                    url: '/templates',
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useGetTemplatesQuery } = apiSlice;
