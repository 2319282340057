import { XYPosition } from 'reactflow';
import { api } from '../../../api';
import { IAction } from '../interfaces/action.interface';
import { IBranch } from '../interfaces/branch.interface';
import { IConversations } from '../interfaces/conversations.interface';
import { DocumentsCollection } from '../interfaces/documents.collection.interface';
import { IMessage } from '../interfaces/message.interface';
import { IWorkflow } from '../interfaces/workflow.interface';

interface GetDocumentsDto {
    type: number;
    page: number;
    limit: number;
}
export const apiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getWorkflows: builder.query<IWorkflow[], void>({
            query: () => {
                return {
                    url: '/conversations',
                    method: 'GET',
                };
            },
            providesTags: ['Workflows'],
        }),
        getWorkflowActions: builder.query<IAction[], void>({
            query: () => {
                return {
                    url: '/conversations/actions',
                    method: 'GET',
                };
            },
            providesTags: ['Actions'],
        }),
        AddWorkflow: builder.mutation<
            IWorkflow,
            { type: number; title: string; templateId: number }
        >({
            query: (payload) => {
                return {
                    url: '/conversations',
                    method: 'POST',
                    body: payload,
                };
            },
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    const { data: conversation } = await queryFulfilled;
                    const patchResult = dispatch(
                        api.util.updateQueryData(
                            'getWorkflows' as never,
                            undefined as never,
                            (draft: IWorkflow[]) => {
                                if (conversation) {
                                    draft.unshift(conversation);
                                    return draft;
                                }
                            },
                        ),
                    );
                    console.log('patchResult', patchResult);
                } catch {}
            },
        }),
        addConversation: builder.mutation<
            any,
            {
                convoId: number;
                messages: IMessage[];
                branches: IBranch[];
                newNodeIds: number[];
                newBranchIds: number[];
                nodeCoordinates: { id: string; position: XYPosition }[];
                deletedMessageIds: number[];
                deletedBranchIds: number[];
            }
        >({
            query: ({
                convoId,
                messages,
                branches,
                newNodeIds,
                newBranchIds,
                deletedMessageIds,
                deletedBranchIds,
                nodeCoordinates,
            }) => {
                const body = {
                    convoId: convoId,
                    convoMessages: messages.map((message) => {
                        const msg: any = { ...message };
                        newNodeIds.includes(message.id) && delete msg['id'];
                        delete msg?.createdAt;
                        delete msg?.updatedAt;
                        return {
                            ...msg,
                            action: msg.action && msg.action.id,
                        };
                    }),
                    branches: branches.map((branch) => {
                        const br: any = { ...branch };
                        delete br.createdAt;
                        delete br.updatedAt;
                        if (br.id == 0 || newBranchIds.includes(br.id)) {
                            delete br.id;
                        }
                        return {
                            ...br,
                            from: br.from.id,
                            to: br.to.id,
                            fromIndex: newNodeIds.includes(br.from.id),
                            toIndex: newNodeIds.includes(br.to.id),
                            condition: br.condition,
                        };
                    }),
                    deletedMessageIds,
                    deletedBranchIds,
                    nodeCoordinates,
                };
                console.log('branch', body);
                return {
                    url: '/conversations/addchat',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Conversations'],
        }),
        getConversations: builder.query<IConversations, { id: number }>({
            query: ({ id }) => {
                return {
                    url: `/conversations/${id}`,
                    method: 'GET',
                };
            },
            providesTags: ['Conversations'],
            transformResponse: (rawResult: any) => {
                return {
                    ...rawResult,
                    coordinates:
                        (rawResult.metadata != undefined &&
                            rawResult.metadata.coordinates) ||
                        [],
                };
            },
        }),
        activateWorkflow: builder.mutation<boolean, { id: number }>({
            query: ({ id }) => {
                return {
                    url: `/conversations/${id}/activate`,
                    method: 'PATCH',
                };
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: isActive } = await queryFulfilled;
                    const patchResult = dispatch(
                        api.util.updateQueryData(
                            'getWorkflows' as never,
                            undefined as never,
                            (draft: IWorkflow[]) => {
                                if (isActive) {
                                    const index1: any = draft.findIndex(
                                        (item) => item.id == id,
                                    );
                                    const index2: any = draft.findIndex(
                                        (item) => item.isActive,
                                    );
                                    draft[index1].isActive = isActive;
                                    draft[index2].isActive = !isActive;
                                    return draft;
                                }
                            },
                        ),
                    );
                    console.log('patchResult', patchResult);
                } catch {}
            },
        }),
        addDocument: builder.mutation<any, FormData>({
            query: (body) => {
                return {
                    url: 'documents',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Documents'],
        }),
        getDocuments: builder.query<DocumentsCollection, GetDocumentsDto>({
            query: ({ type, page, limit }) => {
                return {
                    url: `documents?type=${type}&page=${page}&limit=${limit}`,
                    method: 'GET',
                };
            },
            providesTags: ['Documents'],
        }),
        deleteDocuments: builder.mutation<boolean, { id: number }>({
            query: ({ id }) => {
                return {
                    url: `documents/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['Documents'],
        }),
        deleteWorkflow: builder.mutation<Object, { id: number }>({
            query: ({ id }) => {
                return {
                    url: `conversation/${id}`,
                    method: 'DELETE',
                };
            },
        }),
    }),
});

export const {
    useGetWorkflowsQuery,
    useAddWorkflowMutation,
    useActivateWorkflowMutation,
    useGetConversationsQuery,
    useAddConversationMutation,
    useGetDocumentsQuery,
    useDeleteDocumentsMutation,
    useDeleteWorkflowMutation,
    useAddDocumentMutation,
    useGetWorkflowActionsQuery,
} = apiSlice;
