import { Dropdown, Menu } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
export function ContextMenu({
    onClick,
    top,
    left,
    right,
    bottom,
    onDuplicate,
}: any) {
    const { t } = useTranslation();
    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<CopyOutlined />} onClick={onDuplicate}>
                {t('duplicate')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            visible
            trigger={['contextMenu']}
            onVisibleChange={onClick}
        >
            <div style={{ position: 'absolute', top, left, right, bottom }} />
        </Dropdown>
    );
}
