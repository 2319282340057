import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "./query.replies.module.css";
import { Card, Col, Row } from "antd";
import moment from "moment";
import jwt_decode from "jwt-decode";
import useAxios from "../../configs/axios.config";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import TextArea from "antd/lib/input/TextArea";
import { url } from "inspector";
import { ReplyMessageType } from "../userqueries/user.queries";
import showNotification from "../../common/components/notifications";

const QueryReplies = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [axiosInstance, responseInterceptor]: any = useAxios();
  const [replies, setReplies] = useState<any>(null);
  const token: any = searchParams.get("data");
  let id = jwt_decode(token);

  useEffect(() => {
    const fetchReplies = async () => {
      try {
        let response = await axiosInstance.get(`responses/${0}`);
        if (response.status == 200) {
          setReplies(response.data);
          showNotification({ desc: "Successfull", type: "success" });
        } else {
          throw new Error(response?.response?.data?.message);
        }
      } catch (err: any) {
        console.log("err", err);
        showNotification({ desc: err?.message, type: "error" });
      }
    };
    if (id) {
      fetchReplies();
    }
  }, [id]);

  return (
    <div className={classes.main}>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            style={{ marginTop: "0.5rem", width: "100%", height: "90vh" }}
            title={
              replies?.type == ReplyMessageType.Text
                ? "Text"
                : replies?.type == ReplyMessageType.Attachment
                ? "Attachment"
                : "Audio"
            }
            bordered={false}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <p>
                <b>Date: </b>
                {moment(replies?.createdAt).format("DD/MM/YYYY")}
              </p>
              {replies?.type == 0 ? (
                <TextArea value={replies?.text} />
              ) : replies?.type == 2 ? (
                <audio controls style={{ width: "100%" }} src={replies?.url} />
              ) : (
                <iframe src={replies?.url} style={{ height: "70vh" }}></iframe>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default QueryReplies;
