import React from "react";
import "./App.css";
import AppRoutes from "./routes/app.routes";
import "antd/dist/antd.min.css";
import "reactflow/dist/style.css";
import { ReactFlowProvider } from "reactflow";

function App() {
    return (
            <ReactFlowProvider>
            <AppRoutes />
            </ReactFlowProvider>
    );
}

export default App;
