import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import i18n from '../../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { CustomDivider } from '../custom.divider.component';

interface Props {
    type: string;
    initialValues: any;
}

type Direction = 'ltr' | 'rtl';

const ButtonsFormComponent: React.FC<Props> = ({ type, initialValues }) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState<Direction>('ltr');
    useEffect(() => {
        const newDirection = t('direction') as Direction;
        setDirection(newDirection);
    }, [i18n.language, t]);
    return (
        <div>
            <Form.Item
                style={{ marginBottom: '5px', direction: direction }}
                labelAlign="left"
                label={t('form.node_button.title')}
                name={`${type}_title`}
                rules={[{}]}
            >
                {
                    <Input
                        type={'text'}
                        placeholder={t('form.node_button.title_placeholder')}
                        defaultValue={initialValues.title || undefined}
                    />
                }
            </Form.Item>
            <CustomDivider />
            <Typography
                style={{
                    paddingBottom: '10px',
                    fontSize: '16',
                    fontWeight: 'bold',
                    direction: direction,
                }}
            >
                Buttons (Max. 3 allowed)
            </Typography>
            <Form.List
                name={`${type}_buttons`}
                initialValue={initialValues.buttons}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row gutter={4} style={{ marginRight: "20px" }}>
                                <Col span={5}>
                                    <Form.Item
                                        wrapperCol={{ span: 24}}
                                        style={{ marginBottom: "5px" }}
                                        {...restField}
                                        name={[name, 'id']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'form.node_button.missing_id',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t(
                                                'form.node_button.title_id',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        wrapperCol={{ span: 24}}
                                        style={{ marginBottom: "5px" }}
                                        {...restField}
                                        name={[name, 'title']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'form.node_button.missing_title',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t(
                                                'form.node_button.title_title',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button disabled={fields.length < 2} onClick={() => remove(name)}>-</Button>
                                </Col>
                            </Row>
                        ))}
                        <Form.Item 
                            wrapperCol={{ span: 24 }}>
                            <Button
                                type="dashed"
                                disabled={fields.length > 2}
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                {`${t('form.node_button.add_button')}`}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default ButtonsFormComponent;
