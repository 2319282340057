import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export const InputComponents = {
    Input,
    InputAdornment,
    Button,
    OutlinedInput
};

export const LayoutsComponent = {
    Grid,
    Box
};

export const SurfaceComponents = {
    Card,
};

export const DataDisplayComponents = {
    Typography,
};

export const IconsComponent = {
    IconButton,
    Visibility,
    VisibilityOff,
};

export const FeedbackComponents = {
    CircularProgress,
};
