import React from 'react';
import { Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

interface FeedBackModalInterface {
    isFbModalOpen: boolean;
    setIsFbModalOpen: (isModalOpen: boolean) => void;
    setFeedBack: (feedBack: string) => void;
    feedBack: string;
    handleOk: () => void;
    isLoading: boolean;
}
const FeedBackModal = ({
    isFbModalOpen,
    setIsFbModalOpen,
    setFeedBack,
    feedBack,
    handleOk,
    isLoading,
}: FeedBackModalInterface) => {
    const { t } = useTranslation();
    const handleCancel = () => {
        setFeedBack('');
        setIsFbModalOpen(false);
    };

    return (
        <>
            <Modal
                title={t('give_feedback')}
                afterClose={() => setFeedBack('')}
                open={isFbModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t('ok')}
                cancelText={t('cancel')}
                confirmLoading={isLoading}
            >
                <TextArea
                    placeholder={t('feedback')}
                    value={feedBack}
                    onChange={(e) => setFeedBack(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default FeedBackModal;
