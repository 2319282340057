import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { XYPosition } from 'reactflow';
import { error } from 'console';
import { act } from 'react-dom/test-utils';

export interface PositionAxis {
    id: any;
    position: XYPosition;
}

interface PositionState {
    positions: PositionAxis[];
}

const initialState: PositionState = {
    positions: [],
};

export const workflowSlice = createSlice({
    initialState,
    name: 'workflowSlice',
    reducers: {
      storeNodePositions: (
            state,
            action: PayloadAction<{
                positions: PositionAxis[];
                workflowId: any;
            }>,
        ) => {
            const position = action.payload.positions;
            const id = action.payload.workflowId;
            localStorage.setItem(id, JSON.stringify(position));
        },
        retrieveNodePositions: (state, action: PayloadAction<any>) => {
            const workflowId = action.payload;
            const positionString = localStorage.getItem(workflowId);
            if (positionString !== null) {
                state.positions = JSON.parse(positionString);
            }
        },
    },
});
export default workflowSlice.reducer;
