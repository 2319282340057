import { useEffect, useRef, Fragment, useState } from 'react';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import classes from './table.module.css';
import useAxios from '../../configs/axios.config';
import showNotification from '../../common/components/notifications';
import { transformCsvData } from '../../common/util';
import { useTranslation } from 'react-i18next';

export const CsvComponent = ({ filterMenu, version }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [axiosInstance]: any = useAxios();
    const [csvData, setCsvData] = useState<any>(null);
    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);
    useEffect(() => {
        if (csvData && csvLinkRef.current && csvLinkRef.current.link) {
            setTimeout(() => {
                csvLinkRef?.current?.link.click();
                setCsvData(null);
            });
        }
    }, [csvData]);
    async function fetchAllQueries() {
        setIsLoading(true);
        try {
            let response = await axiosInstance.get('queries');
            if (response?.status === 200) {
                const transformedData = transformCsvData(
                    response.data.data,
                    filterMenu,
                    version
                );
                setCsvData(transformedData);
            } else {
                throw new Error(response?.response?.data?.message);
            }
        } catch (err: any) {
            console.log('err', err);
            showNotification({
                desc: err?.message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            <Button
                loading={isLoading}
                type="primary"
                style={{ marginBottom: '0.5rem' }}
                onClick={fetchAllQueries}
                className={classes.CSVButton}
            >
                {t('downloadCSV')}
            </Button>
            {csvData && <CSVLink data={csvData} ref={csvLinkRef} />}
        </Fragment>
    );
};
